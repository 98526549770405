import Page404 from '../Pages/NotFound/Page404';
import TimeSheetPage from '../Pages/TimeSheet/TimeSheetPage';
import UserManagementPage from '../Pages/UserManagement/UserManagementPage';
import Project from '../Pages/Project/Project';
import AddProject from '../Components/Project/AddProject';
import AddUserPage from '../Pages/UserManagement/AddUserPage';
import Master from '../Pages/Master/Master';
import Module from '../Pages/Module/Module';
import { Department } from '../Pages/Departments/Department';
import { Designation } from '../Pages/Designations/Designation';
import UserRole from '../Components/UserRole/UserRoleComponent';
import Leave from '../Pages/Leave/Leave';
import AttendanceReport from '../Pages/Reports/AttendanceReport';
import EffortVarience from '../Pages/Reports/EffortVarience';
import AddUserType from '../Components/UserManagement/AddUserType';
import ViewProfile from '../Pages/ViewProfiles/ViewProfile';
import AddUserRole from '../Components/UserRole/AddUserRole';
import EmailComponent from '../Components/Email/EmailComponent';
import Settings from '../Pages/Settings/Settings';
import { LeaveMangment } from '../Pages/LeaveMangment/LeaveMangment';
import { UserTimesheet } from '../Components/TimeSheet/UsersTimeSheet/UserTimesheet';
import UserTimeSheetView from '../Components/TimeSheet/UsersTimeSheet/UserTimeSheetView';
import ViewProject from '../Components/Project/ViewProject';
import { LeaveManagementView } from '../Components/LeaveMangmentComponent/LeaveManagementView';
import CompetencyMatrix from '../Pages/Reports/CompetencyMatrix';
import UserRequests from '../Components/LeaveMangmentComponent/UserRequests';
import UserManagementView from '../Components/UserManagement/UserManagementView';
import NotificationHistory from '../Pages/Reports/NotificationHistory';
import DraftModule from '../Pages/DraftModule/DraftModule';
import ProjectTable from '../Components/Project/ProjectTable';
import ResetPassword from '../Components/Login/ResetPassword';
import DepartmentReport from '../Pages/Reports/DepartmentReport';

import Dashboard from '../Pages/Dashboard/Dashboard';
import UserReport from '../Components/Reports/DepartmentReport/UserReport';
import UserReportPage from '../Pages/Reports/UserReportPage';
import UserLog from '../Pages/UserLog/UserLog';

export const routes = [
  //dashboard
  {
    path: '/dashboard',
    component: <Dashboard />,
  },
  //Login
  { path: '/reset_password/:emailId', component: <ResetPassword /> },
  //my-timesheet
  { path: '/timesheet', component: <TimeSheetPage /> },
  { path: '/user_timesheet', component: <UserTimesheet /> },
  { path: '/my_user_timesheet/:edit_id', component: <UserTimeSheetView /> },
  //projects
  { path: '/projects', component: <Project /> },
  { path: '/projects/:view_type', component: <Project /> },
  { path: '/add_Project/:view_type', component: <AddProject /> },
  { path: '/add_Project/:view_type/:edit_id', component: <AddProject /> },
  {
    path: '/View_Project/:view_type/:edit_id/:editPermission',
    component: <ViewProject />,
  },
  { path: '/Project_Table', component: <ProjectTable /> },
  { path: '/module', component: <Module /> },
  //users
  { path: '/user', component: <UserManagementPage /> },
  { path: '/add_user', component: <AddUserPage /> },
  { path: '/add_user/:edit_id', component: <AddUserPage /> },
  { path: '/user_role', component: <UserRole /> },
  { path: '/add_user_Type', component: <AddUserType /> },
  { path: '/add_user_Role/:edit_id', component: <AddUserRole /> },
  { path: '/add_user_Role', component: <AddUserRole /> },
  //masters
  { path: '/masters', component: <Master /> },
  { path: '/draft_module', component: <DraftModule /> },
  { path: '/department', component: <Department /> },
  { path: '/designation', component: <Designation /> },
  { path: '/leaves', component: <Leave /> },
  { path: '/email_notification', component: <EmailComponent /> },
  //reports
  { path: '/attendance_report', component: <AttendanceReport /> },
  { path: '/effort_variance', component: <EffortVarience /> },
  { path: '/department_report', component: <DepartmentReport /> },
  { path: '/user_report', component: <UserReportPage /> },
  { path: '/Competency_Matrix', component: <CompetencyMatrix /> },
  {
    path: '/department_view/:department_id/:department_name',
    component: <UserReport />,
  },
  { path: '/notification', component: <NotificationHistory /> },

  //settings
  { path: '/settings', component: <Settings /> },
  //User Logs
  { path: '/user_log', component: <UserLog /> },
  //leaves - Permissions
  { path: '/my_requests', component: <LeaveMangment /> },
  { path: '/my_requests/view', component: <LeaveManagementView /> },
  {
    path: '/my_requests/view/:edit_id/:userType/:permission',
    component: <LeaveManagementView />,
  },
  { path: '/user_requests/view', component: <LeaveManagementView /> },
  {
    path: '/user_requests/view/:edit_id/:userType/:permission',
    component: <LeaveManagementView />,
  },
  { path: '/user_requests', component: <UserRequests /> },
  {
    path: '/user/view/:edit_id/:permission',
    component: <UserManagementView />,
  },
  { path: '/my_requests_permission', component: <LeaveMangment /> },
  { path: '/my_requests_permission/view', component: <LeaveManagementView /> },
  {
    path: '/my_requests_permission/view/:edit_id/:userType/:permission',
    component: <LeaveManagementView />,
  },
  { path: '/user_requests_permission', component: <UserRequests /> },
  {
    path: '/user_requests_permission/view',
    component: <LeaveManagementView />,
  },
  {
    path: '/user_requests_permission/view/:edit_id/:userType/:permission',
    component: <LeaveManagementView />,
  },
  //common-routes
  { path: '/View_Profile', component: <ViewProfile /> },
  //unknown path handler
  { path: '*', component: <Page404 /> },
];
