import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import ShowStatus from '../ReusableComponents/ShowStatus';
import TmPagination from '../ReusableComponents/Pagination';
import { NORECORD, PaginationRange } from '../../constant/constant';

dayjs.extend(relativeTime);
const UserLogTable = ({ columns, rows }) => {
  const [pagination, setPagination] = useState([]);
  useEffect(() => {
    PaginationRange.setPageSize(10);
    handlePagination(1, rows);
  }, [rows]);

  const onShowSizeChange = (current, pageSize) => {
    PaginationRange.setPageSize(pageSize);
    handlePagination(current, rows);
  };
  const handlePagination = async (data, current = []) => {
    let endRange = data * Number(PaginationRange.endRange) - 1;
    let startRange =
      data * Number(PaginationRange.startRange) -
      Number(PaginationRange.initalStart);
    const check = current.length ? current : rows;
    setPagination(
      check.filter((_, index) => index <= endRange && index >= startRange),
    );
  };

  return (
    <div>
      <Table responsive className="mt-2">
        <thead>
          <tr>
            {columns.map((item, index) => (
              <th key={index} className={`${item.className} text-nowrap`}>
                {item.headerName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {pagination.length <= 0 && (
            <tr>
              <td colSpan={100} className="text-center">
                {NORECORD}
              </td>
            </tr>
          )}
          {pagination.length > 0 &&
            pagination?.map((item, idx) => (
              <tr key={idx} className="mt-md-2">
                <td className="mt-md-2">
                  <span>{<ShowStatus status={item?.logType} />}</span>
                </td>

                <td>{item.department_name || '--'} </td>
                <td>{item.date || '--'} </td>
                <td>
                  <span className="mb-0 py-0 px-2 rounded-pill">
                    {item?.logContent} -{' '}
                    <span style={{ color: '#646871' }}>
                      {dayjs(item.createdAt, 'DD-MM-YYYY h:mm A').fromNow()}{' '}
                    </span>
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div className="mt-2 mt-md-0">
        <TmPagination
          onShowSizeChange={onShowSizeChange}
          total={rows.length}
          handlePagination={handlePagination}
        />
      </div>
    </div>
  );
};

export default UserLogTable;
