import React from 'react';

const ShowStatus = ({ status }) => {
  const statusMap = {
    inactive: { class: 'alert-danger', label: 'In Active' },
    active: { class: 'alert-success', label: 'Active' },
    rejected: { class: 'alert-danger', label: 'Rejected' },
    approved: { class: 'alert-success', label: 'Approved' },
    pending: { class: 'alert-warning', label: 'Pending' },
    completed: { class: 'alert-success', label: 'Completed' },
    'not yet started': { class: 'alert-secondary', label: 'Not yet started' },
    inprogress: { class: 'alert-warning', label: 'Inprogress' },
    sent: { class: 'alert-success', label: 'Delivered' },
    unsent: { class: 'alert-danger', label: 'Not Delivered' },
    working: { class: 'alert-success', label: 'Working' },
    notworking: { class: 'alert-danger', label: 'Not Working' },
    missed: { class: 'alert-secondary', label: 'MISSED' },
    unlocked: { class: 'alert-success', label: 'Unlocked' },
    locked: { class: 'alert-warning', label: 'LOCKED' },
    'leave submission': { class: 'alert-secondary', label: 'Leave Submission' },
    'leave updation': { class: 'alert-warning', label: 'Leave Updation' },
    'leave deletion': { class: 'alert-danger', label: 'Leave Deleted' },
    'leave rejection': { class: 'alert-danger', label: 'Leave Rejected' },
    'leave approval': { class: 'alert-success', label: 'Leave Approved' },
    'month approval': { class: 'alert-success', label: 'Month Approved' },
    'forget password': { class: 'alert-secondary', label: 'Forget Password' },
    'lock timesheet': {
      class: 'alert-danger',
      label: 'Timesheet Locked',
    },
    'lock remainder': {
      class: 'alert-warning',
      label: 'Timesheet Remainder',
    },
    'user creation': { class: 'alert-success', label: 'Registration' },
    'permission submission': {
      class: 'alert-secondary',
      label: 'Permission Submission',
    },
    'permission updation': {
      class: 'alert-warning',
      label: 'Permission Updation',
    },
    'permission deletion': {
      class: 'alert-danger',
      label: 'Permission Deleted',
    },
    'permission rejection': {
      class: 'alert-danger',
      label: 'Permission Rejected',
    },
    'permission approval': {
      class: 'alert-success',
      label: 'Permission Approved',
    },
  };

  const currentStatus = statusMap[String(status).toLowerCase()];

  return currentStatus ? (
    <span
      className={`alert ${currentStatus.class} mb-0 py-0 px-2 rounded-pill`}
    >
      {currentStatus.label}
    </span>
  ) : null;
};

export default ShowStatus;

// import React from 'react';

// const ShowStatus = ({ status }) => {
//   const handleStatus = (status) => {
//     let newStatus = String(status).toLowerCase();
//     switch (newStatus) {
//       case 'inactive':
//         return (
//           <span className="alert alert-danger mb-0 py-0 px-2 rounded-pill">
//             In Active
//           </span>
//         );
//         break;
//       case 'active':
//         return (
//           <span className="alert alert-success mb-0 py-0 px-2 rounded-pill">
//             Active
//           </span>
//         );
//         break;
//       case 'rejected':
//         return (
//           <span className="alert alert-danger mb-0 py-0 px-2 rounded-pill">
//             Rejected
//           </span>
//         );
//         break;

//       case 'approved':
//         return (
//           <span className="alert alert-success mb-0 py-0 px-2 rounded-pill">
//             Approved
//           </span>
//         );
//         break;
//       case 'pending':
//         return (
//           <span className="alert alert-warning mb-0 py-0 px-2 rounded-pill">
//             Pending
//           </span>
//         );
//         break;
//       case 'completed':
//         return (
//           <span className="alert alert-success mb-0 py-0 px-2 rounded-pill">
//             Completed
//           </span>
//         );
//         break;
//       case 'not yet started':
//         return (
//           <span className="alert alert-secondary mb-0 py-0 px-2 rounded-pill">
//             Not yet started
//           </span>
//         );
//         break;
//       case 'inprogress':
//         return (
//           <span className="alert alert-warning mb-0 py-0 px-2 rounded-pill">
//             Inprogress
//           </span>
//         );
//         break;
//       case 'sent':
//         return (
//           <span className="alert alert-success mb-0 py-0 px-2 rounded-pill">
//             Delivered
//           </span>
//         );
//         break;
//       case 'unsent':
//         return (
//           <span className="alert alert-danger mb-0 py-0 px-2 rounded-pill">
//             Not Delivered
//           </span>
//         );
//         break;
//       case 'working':
//         return (
//           <span className="alert alert-success mb-0 py-0 px-2 rounded-pill">
//             Working
//           </span>
//         );
//         break;
//       case 'notworking':
//         return (
//           <span className="alert alert-danger mb-0 py-0 px-2 rounded-pill">
//             Not Working
//           </span>
//         );
//         break;
//       case 'rejected':
//         return (
//           <span className="alert alert-danger mb-0 py-0 px-2 rounded-pill">
//             REJECTED
//           </span>
//         );
//         break;
//       case 'missed':
//         return (
//           <span className="alert alert-secondary mb-0 py-0 px-2 rounded-pill">
//             MISSED
//           </span>
//         );
//         break;
//       case 'unlocked':
//         return (
//           <span className="alert alert-success mb-0 py-0 px-2 rounded-pill">
//             Unlocked
//           </span>
//         );
//         break;

//       case 'locked':
//         return (
//           <span className="alert alert-warning mb-0 py-0 px-2 rounded-pill">
//             LOCKED
//           </span>
//         );
//         break;
//       case 'leave submission':
//         return (
//           <span className="alert alert-secondary mb-0 py-0 px-2 rounded-pill">
//             Submission
//           </span>
//         );
//         break;
//       case 'leave updation':
//         return (
//           <span className="alert alert-secondary mb-0 py-0 px-2 rounded-pill">
//             Updation
//           </span>
//         );
//         break;
//       case 'leave deletion':
//         return (
//           <span className="alert alert-danger mb-0 py-0 px-2 rounded-pill">
//             Deleted
//           </span>
//         );
//         break;
//       case 'leave rejection':
//         return (
//           <span className="alert alert-danger mb-0 py-0 px-2 rounded-pill">
//             Rejected
//           </span>
//         );
//         break;

//       case 'leave approval':
//         return (
//           <span className="alert alert-success mb-0 py-0 px-2 rounded-pill">
//             Approved
//           </span>
//         );
//         break;

//       default:
//         break;
//     }
//   };
//   return <span>{handleStatus(status)}</span>;
// };

// export default ShowStatus;
