import React, { useEffect, useState } from 'react';
import leaveImg from '../../Assets/leave.png';
import sickImg from '../../Assets/sick.png';
import maternityImg from '../../Assets/maternity.png';
import morningImg from '../../Assets/morning.png';
import eveningImg from '../../Assets/night.png';
import wedding from '../../Assets/wedding.png';
import family from '../../Assets/family.png';
import {
  generateCurrentMonth,
  generateCurrentYear,
  generateYears,
} from '../../ReusableFn/reusableFn';
import { MONTHS } from '../../constant/constant';
import { FcCustomerSupport } from 'react-icons/fc';
import style from '../../Styles/Dasbboard.module.css';
import { Dropdown, Progress } from 'antd';
import { MdOutlineLockClock } from 'react-icons/md';
import { SmileOutlined } from '@ant-design/icons';
import NoDataFound from '../ReusableComponents/NoDataFound';

import noDataFound from '../../Assets/No Data found.png';
import AntdModalComp from '../ReusableComponents/AntdModalComp';
import DashboardCardView from './DashboardCardView';
import {
  EmployeesleaveTaken,
  PermissionsTaken,
} from '../../constant/tableHeader';
import DashboardApi from '../../Api/DashboardApi';
import { useAuth } from '../../Context/AuthContext';
import { toastError, toastInfo } from '../Toast/ToastComponent';

const EmployeeReport = ({
  permissionTaken,
  leaveTaken,
  lockedCount,
  filterArr,
}) => {
  const [change, setChange] = useState('');
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);

  const toggleModal = () => {
    setModal(!modal);
  };
  const { authState } = useAuth();

  const getEmployeesTaken = async (changeEvent) => {
    let reqData = {
      ...filterArr,
      userRoleID: authState?.userRoleID,
      id: authState?.id,
    };
    let response;

    try {
      if (changeEvent === 'Evening' || changeEvent === 'Morning') {
        reqData.type = changeEvent;
        response = await DashboardApi.permissionTakenReport(reqData);
      } else {
        reqData.type = changeEvent;
        response = await DashboardApi.leaveTakenReport(reqData);
      }
      if (response.data.success) {
        setData(response.data.result);
      } else {
        toastInfo(response?.data?.message);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleCardClick = async (event) => {
    setChange(event);
    await getEmployeesTaken(event);
    toggleModal();
  };

  const columns =
    change === 'Evening' || change === 'Morning'
      ? PermissionsTaken
      : EmployeesleaveTaken;

  return (
    <div className={`row p-0 m-0   ${style.dashTaken}  ps-1`}>
      <div className="col-lg-12   p-0 pe-1">
        <div className=" bg-white shadow-sm rounded p-2">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="d-block fw-bold my-2">Employees leave Taken</div>
            <div className="d-flex"></div>
          </div>
          <div className=" row px-0 m-0 flex-wrap">
            <div
              className={`ps-0 col-lg-4 d-flex justify-content-center p-1 align-items-center ${style.Casualcard}`}
            >
              <div
                onClick={() => handleCardClick('Casual leave')}
                className={` mt-2 border rounded p-1 w-100`}
                style={{ cursor: 'pointer' }}
              >
                <div className="d-flex justify-content-between align-items-center ">
                  <div className={` ${style.cardAlignment}`}>
                    <div className={` ${style.iconAlignment}`}>
                      <img src={leaveImg} alt="Casual leave" />
                    </div>
                    <div className="ms-1 fw-semibold">Casual Leave</div>
                  </div>

                  <div
                    className={`alert alert-primary p-0 mb-0 d-fcc ${style.takenCard}`}
                  >
                    {leaveTaken?.totalResult?.[0]?.casualLeave || 0}
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`ps-0 col-lg-4  d-flex justify-content-center p-1 align-items-center ${style.Casualcard}`}
            >
              {' '}
              <div
                className={` mt-2 border rounded p-1 w-100  `}
                onClick={() => handleCardClick('Sick leave')}
                style={{ cursor: 'pointer' }}
              >
                <div className="d-flex justify-content-between align-items-center ">
                  <div className={` ${style.cardAlignment}`}>
                    <div className={` ${style.iconAlignment}`}>
                      <img src={sickImg} alt="Sick Leave" />
                    </div>
                    <div className="ms-1 fw-semibold">Sick Leave</div>
                  </div>

                  <div
                    className={`alert alert-primary p-0 mb-0 d-fcc ${style.takenCard}`}
                  >
                    {leaveTaken?.totalResult?.[0]?.sickLeave || 0}
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`ps-0 col-lg-4  d-flex justify-content-end mb-0 p-1 align-items-center ${style.Casualcard}`}
            >
              {' '}
              <div
                className={` mt-2 border border rounded p-1 w-100 `}
                onClick={() => handleCardClick('Marriage leave')}
                style={{ cursor: 'pointer' }}
              >
                <div className="d-flex justify-content-between align-items-center ">
                  <div className={` ${style.cardAlignment}`}>
                    <div className={` ${style.iconAlignment}`}>
                      <img
                        src={wedding}
                        width={20}
                        height={20}
                        alt="Marriage Leave"
                      />
                    </div>
                    <div className="ms-1 fw-semibold">Marriage Leave</div>
                  </div>{' '}
                  <div
                    className={`alert alert-primary p-0 mb-0 d-fcc ${style.takenCard}`}
                  >
                    {leaveTaken?.totalResult?.[0]?.marriageLeave || 0}
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`ps-0 col-lg-4 d-flex justify-content-center p-1 align-items-center ${style.Casualcard}`}
            >
              <div
                className={` mt-2  border rounded p-1 w-100 `}
                onClick={() => handleCardClick('Maternity leave')}
                style={{ cursor: 'pointer' }}
              >
                <div className="d-flex justify-content-between align-items-center ">
                  <div className={` ${style.cardAlignment}`}>
                    <div className={` ${style.iconAlignment}`}>
                      <img src={maternityImg} alt="Maternity Leave" />
                    </div>
                    <div className="ms-1 fw-semibold">Maternity Leave</div>
                  </div>{' '}
                  <div
                    className={`alert alert-primary p-0 mb-0 d-fcc ${style.takenCard}`}
                  >
                    {leaveTaken?.totalResult?.[0]?.maternityLeave || 0}
                  </div>
                </div>
              </div>{' '}
            </div>

            <div
              className={`ps-0 col-lg-4  d-flex  p-1 align-items-center ${style.Casualcard}`}
            >
              {' '}
              <div
                className={` mt-2 border border rounded p-1 w-100 `}
                onClick={() => handleCardClick('Paternity leave')}
                style={{ cursor: 'pointer' }}
              >
                <div className="d-flex justify-content-between align-items-center ">
                  <div className={` ${style.cardAlignment}`}>
                    <div className={` ${style.iconAlignment}`}>
                      <img
                        src={family}
                        width={20}
                        height={20}
                        alt="Paternity Leave"
                      />
                    </div>
                    <div className="ms-1 fw-semibold">Paternity Leave</div>
                  </div>{' '}
                  <div
                    className={`alert alert-primary p-0 mb-0 d-fcc ${style.takenCard}`}
                  >
                    {leaveTaken?.totalResult?.[0]?.paternityLeave || 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-2  p-0  pe-1">
        <div className="bg-white shadow-sm rounded  p-2 h-100">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-block fw-bold my-2">Permissions Taken</div>
            <div className="d-flex"></div>
          </div>
          <div
            className={`d-lg-flex justify-content-start  align-items-center`}
          >
            <div
              className={` me-2 ${style.permissionres}`}
              onClick={() => handleCardClick('Morning')}
              style={{ cursor: 'pointer' }}
            >
              <div className="mt-2 border rounded p-1">
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    <span>
                      <img src={morningImg} alt="Morning" />
                    </span>
                    <span className="ms-1 fw-semibold">Morning</span>
                  </span>

                  <div
                    className={`alert alert-primary p-0 mb-0 d-fcc ${style.takenCard}`}
                  >
                    {permissionTaken?.totalResult?.[0]?.Morning || 0}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={` me-2 ${style.permissionres}`}
              onClick={() => handleCardClick('Evening')}
              style={{ cursor: 'pointer' }}
            >
              <div className="mt-2 border rounded p-1">
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    <span>
                      <img src={eveningImg} alt="Evening" />
                    </span>
                    <span className="ms-1 fw-semibold">Evening</span>
                  </span>

                  <div
                    className={`alert alert-primary p-0 mb-0 d-fcc ${style.takenCard}`}
                  >
                    {permissionTaken?.totalResult?.[0]?.Evening || 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AntdModalComp
          width="40%"
          height={350}
          toggleModal={toggleModal}
          modalOpen={modal}
          modalHeader={
            <>
              Employees List <span style={{ fontSize: 12 }}>({change})</span>
            </>
          }
          modalBody={
            <DashboardCardView
              columns={columns}
              rows={data}
              change={change}
              setModal={setModal}
            />
          }
        />
      </div>
    </div>
  );
};

export default EmployeeReport;
