import React, { Fragment, useEffect, useState } from 'react';

import { FaArrowLeft } from 'react-icons/fa';
import style from '../../Styles/LeaveMangment.module.css';
import { Steps, Button } from 'antd';
import { Col, Row } from 'reactstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toastError, toastInfo } from '../Toast/ToastComponent';
import { LeaveManageApi } from '../../Api/LeaveManageApi';
import { useAuth } from '../../Context/AuthContext';
import CommentModal from './CommentModal';
import ModalComponent from '../ReusableComponents/ModalComponent';
import LeaveLogData from './LeaveLogData';
import Loader from '../ReusableComponents/Loader';
import { Permissions } from '../../Api/Permisisons';
import {
  PERMISSION_PAGE_ROUTES,
  TIMESHEET_PAGE_ROUTES,
} from '../../constant/constant';

let modalHeader, modalData;

export const LeaveManagementView = ({
  modalId,
  modalUserType,
  buttonPermissions,
  leaveToggle,
  isPermission,
}) => {
  const { pathname } = useLocation();

  const { edit_id, userType, permission } = useParams();

  const [isPermissionComp, setIsPermissionComp] = useState(false);

  let buttonPermission = TIMESHEET_PAGE_ROUTES.includes(pathname.split('/')[1])
    ? buttonPermissions
    : JSON.parse(atob(permission));

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { authState } = useAuth();

  const [viewData, setViewData] = useState(undefined);

  const getData = async () => {
    try {
      const response =
        PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1]) || isPermission
          ? await Permissions.view({ id: modalId ? modalId : atob(edit_id) })
          : await LeaveManageApi.view({
              id: modalId ? modalId : atob(edit_id),
            });

      if (response?.data?.success) {
        let data = response?.data?.result;
        const check = data?.logData.findIndex(
          (item) => item.type == 'Rejected' || item.type == 'Approved',
        );
        if (check == -1) {
          let obj = {
            title: 'Waiting',
            description: 'Request is waiting for action',
          };
          data?.logData.push(obj);
        }
        setViewData(data);
        setLoading(true);
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  const toggleModal = (type, value) => {
    if (value && type === 'Edit') {
      modalData = value;
    }
    setModal(!modal);
  };

  const handleApprove = async (value) => {
    let obj = {
      id: viewData?.id,
      user_id: authState.id,
      status: value,
    };

    try {
      const response =
        PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1]) || isPermission
          ? await Permissions.approve(obj)
          : await LeaveManageApi.approve(obj);
      if (response.data.success) {
        getData();
      } else {
        toastInfo(response.data.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  const getIsPermission = () => {
    try {
      setIsPermissionComp(
        PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1])
          ? true
          : isPermission,
      );
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  useEffect(() => {
    getIsPermission();
    getData();
  }, [isPermissionComp, isPermission]);

  const { Step } = Steps;
  const description = '';
  return (
    <Fragment>
      <div>
        <Row className="align-items-center mx-0">
          <Col className=" px-0">
            <div className="d-flex  px-0 pb-1">
              <div
                className={`${style['arrow-icon']} border rounded  px-2 pb-1`}
                onClick={() =>
                  TIMESHEET_PAGE_ROUTES.includes(pathname.split('/')[1])
                    ? leaveToggle()
                    : navigate(-1)
                }
              >
                <FaArrowLeft />
              </div>
              <div className={`mx-1 mt-1 ${style['add-user']}`}>
                {viewData?.type}
              </div>
            </div>
          </Col>
          <Col md={9} className="text-end px-0 py-3">
            <div
              className={`text-end ${
                (modalUserType ? modalUserType : atob(userType)) ==
                  'userRequest' &&
                buttonPermission?.comment &&
                buttonPermission?.approve &&
                buttonPermission?.reject &&
                'd-flex justify-content-end'
              }`}
            >
              {buttonPermission?.comment && (
                <Button
                  onClick={() => toggleModal('Edit', viewData)}
                  type={
                    (modalUserType ? modalUserType : atob(userType)) !==
                    'userRequest'
                      ? 'primary'
                      : 'default'
                  }
                  size="medium"
                  className={`m-1`}
                  htmlType="button"
                >
                  Comment
                </Button>
              )}
              {buttonPermission?.reject &&
                (modalUserType ? modalUserType : atob(userType)) ==
                  'userRequest' && (
                  <Button
                    onClick={() => handleApprove('REJECTED')}
                    type="dashed"
                    size="medium"
                    htmlType="submit"
                    className=" m-1"
                  >
                    Reject
                  </Button>
                )}
              {buttonPermission?.approve &&
                (modalUserType ? modalUserType : atob(userType)) ==
                  'userRequest' && (
                  <Button
                    onClick={() => handleApprove('APPROVED')}
                    type="primary"
                    size="medium"
                    htmlType="submit"
                    className="m-1"
                  >
                    Approve
                  </Button>
                )}
            </div>
          </Col>
        </Row>
        <div>
          {loading &&
            (PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1]) ||
            isPermission ? (
              <Row className={`mx-0  ${style.LeavemanagementTable}`}>
                <Col md="6" className="px-0 h-100">
                  <div className={`shadow border h-100  me-md-3 p-3 pe-0`}>
                    <div className="mt-2">
                      <h6>Request Details</h6>
                    </div>
                    <div className="mt-4 table-responsive">
                      <table
                        style={{ width: '100%' }}
                        className={`${style.viewTable}`}
                      >
                        <tbody>
                          <tr>
                            <td style={{ width: '40%' }}>Employee Name</td>
                            <td style={{ width: '2%' }}>:</td>
                            <td style={{ width: '50%' }}>
                              {viewData?.username}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: '40%' }}> Date </td>
                            <td style={{ width: '2%' }}>:</td>
                            <td style={{ width: '50%' }}>
                              {viewData?.dateString}
                            </td>
                          </tr>
                          <tr>
                            <td> Month and Year</td>
                            <td>:</td>
                            <td>
                              {viewData?.month} {viewData?.year}
                            </td>
                          </tr>
                          <tr>
                            <td> Permission Time</td>
                            <td>:</td>
                            <td>{viewData?.timeSpent}</td>
                          </tr>
                          <tr>
                            <td> Total Hours</td>
                            <td>:</td>
                            <td> {viewData?.hourCount}</td>
                          </tr>
                          <tr>
                            <td>Permission Type</td>
                            <td>:</td>
                            <td>{viewData?.type} </td>
                          </tr>
                          <tr>
                            <td>Approver</td>
                            <td>:</td>
                            <td> {viewData?.approvers ?? '_'}</td>
                          </tr>
                          <tr>
                            <td>Reason</td>
                            <td>:</td>
                            <td>
                              {' '}
                              <div className={`${style.scrollforReason}`}>
                                {viewData?.reason}
                              </div>{' '}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Col>
                <Col md="6" className="px-0 mt-4 mt-md-0 h-100">
                  <div
                    className={`shadow border p-3 pe-0 h-100 ${style.overflowfosmall}`}
                  >
                    <div className="mt-2 ">
                      <h6>Log</h6>
                    </div>
                    <div className="mt-4 ">
                      <LeaveLogData logData={viewData?.logData} />
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row className={`mx-0  ${style.LeavemanagementTable1}`}>
                <Col md="6" className="px-0 h-100">
                  <div className={`shadow border h-100  me-md-3 p-3 pe-0`}>
                    <div className="mt-2">
                      <h6>Request Details</h6>
                    </div>
                    <div className="mt-4 table-responsive">
                      <table
                        style={{ width: '100%' }}
                        className={`${style.viewTable}`}
                      >
                        <tbody>
                          <tr>
                            <td style={{ width: '25%' }}>Employee Name</td>
                            <td style={{ width: '2%' }}>:</td>
                            <td style={{ width: '65%' }}>
                              {viewData?.username}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: '25%' }}>Start Date</td>
                            <td style={{ width: '2%' }}>:</td>
                            <td style={{ width: '65%' }}>
                              {viewData?.startDate}
                            </td>
                          </tr>
                          <tr>
                            <td>End Date</td>
                            <td>:</td>
                            <td>{viewData?.endDate}</td>
                          </tr>
                          <tr>
                            <td>Number of Days Leave</td>
                            <td>:</td>
                            <td>{viewData?.daycount}</td>
                          </tr>
                          <tr>
                            <td>Leave Type</td>
                            <td>:</td>
                            <td>{viewData?.type}</td>
                          </tr>
                          <tr>
                            <td className="">Approver</td>
                            <td className="">:</td>
                            <td className="">
                              {viewData?.approvers ?? '_'}
                            </td>{' '}
                          </tr>
                          <tr>
                            <td>Reason</td>
                            <td>:</td>
                            <td>
                              <div className={`${style.scrollforReason}`}>
                                {viewData?.reason}
                              </div>
                            </td>
                          </tr>{' '}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Col>
                <Col md="6" className="px-0 mt-4 mt-md-0 h-100">
                  <div className={`shadow border p-3 pe-0 h-100`}>
                    <div className="mt-2">
                      <h6>Log</h6>
                    </div>
                    <div className="mt-4">
                      <LeaveLogData logData={viewData?.logData} />
                    </div>
                  </div>
                </Col>
              </Row>
            ))}{' '}
          {!loading && <Loader />}
        </div>

        <div>
          <ModalComponent
            size="md"
            toggleModal={() => toggleModal('Add', null)}
            modalOpen={modal}
            modalHeader={modalHeader}
            modalBody={
              <CommentModal
                modalData={modalData}
                getLeaveManageList={getData}
                toggleModal={toggleModal}
                isPermission={
                  PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1]) ||
                  isPermission
                }
              />
            }
          />
        </div>
      </div>
    </Fragment>
  );
};
