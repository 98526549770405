export const FORGET_PASSWORD = `<div>
  <table
    style="
      background-color: white;
      margin-left: auto;
      margin-right: auto;
      width: 100%;      
    ">
    <tr>
      <td style="height: 250px;">
        <img src=" #Image# " alt="Image File" style="height: 100%;"/>
      </td>
    </tr>
    <tr>
      <td style="padding-left: 15px; color: #383c51;">
        <h5 style="color: #383c51;">Forgot your password?</h5>
      </td>
    </tr>
    <tr>
      <td style="padding-left: 15px; padding-top: 8px; color: #383c51;">
        Hello #Name# ,
      </td>
    </tr>
    <tr>
      <td
        style="
          padding-left: 15px;
          width: 550px;
          padding-top: 8px;
          color: #383c51;
        ">
        Hello, We’ve received a request to reset the password for account
        associated with #Email# No changes have been made to your account yet.
        You can reset your password by clicking the link below:
      </td>
    </tr>
    <tr>
      <td style="padding-left: 15px; padding-top: 8px; color: #383c51;">
        You can reset your password by clicking the link below:
      </td>
    </tr>
    <tr>
      <td style="text-align: center;">
        <a href=" #URL# ">
          <button
            style="
            background-color: #134780;
            width: 240px;
            height: 35px;
            color: white;
            margin-top: 15px;
            border-radius: 5px;
          ">
            Reset Your Password
          </button>
        </a>
      </td>
    </tr>
    <tr>
      <td style="padding-left: 10px; padding-top: 18px;">
        <div
          style="border-bottom: 2px solid #f8a013; width: 520px;"></div>
      </td>
    </tr>
    <tr>
      <td
        style="
          padding-left: 15px;
          width: 550px;
          padding-top: 8px;
          color: #383c51;
        ">
        Just so you know: You have 24 hours to pick your password. After that,
        you'll have to ask for a new one.
      </td>
    </tr>
    <tr>
      <td
        style="
          padding-left: 15px;
          width: 550px;
          padding-top: 8px;
          color: #383c51;
        ">
        Thanks & Regards,
      </td>
    </tr>
    <tr>
      <td style="padding-left: 17px; width: 550px;">
        <b style="color: #383c51;">Revathy A – Manager - HRD.</b> <br>
        <img src=" #companyLogo# " alt="openwave logo">
        <br>
        <b style="color: #383c51;">Openwave Computing Services (P) Ltd</b> <br>
      </td>
    </tr>
  </table>
</div>
`;

export const TIMESHEET_REMAINDER = `<div style="width: 100%; background-color: #f6f6f6; padding: 20px 0;">
  <table style="background-color: white; margin: 0 auto; width: 600px; border: 1px solid #ddd; border-spacing: 0; font-family: Arial, sans-serif;width: 100%; ">
      <tr>
          <td style="text-align: center; padding: 20px;height:250px">
              <img src="#Image#" alt="Image File" style="height: 100%;" />
          </td>
      </tr>
      <tr>
          <td style="padding: 15px; color: #383C51;">
              Dear #Name#,
          </td>
      </tr>
      <tr>
          <td style="padding: 15px; color: #383C51;">
              Good day!,
          </td>
      </tr>
      <tr>
          <td style="padding: 15px; color: #383C51;">
              This is a friendly reminder to submit your timesheet by every day. If you do not enter your timesheet data, your timesheet will be locked, and you will not be able to make any further entries or modifications.
          </td>
      </tr>
      <tr>
          <td style="padding: 15px; color: #383C51;">
              Action Required:
          </td>
      </tr>
      <tr>
          <td style="padding: 15px; color: #383C51;"><b>Deadline:</b> 12:00 AM #Date#</td>
      </tr>
      <tr>
          <td style="padding: 15px; color: #383C51;">
              <b>Submit Timesheet:</b> Please log in to Timesheet Management Software and complete your timesheet entry for today.
          </td>
      </tr>
      <tr>
          <td style="padding: 15px; color: #383C51;"><b>Login URL:</b> #URL#</td>
      </tr>
      <tr>
          <td style="padding: 15px; color: #383C51;">
              Thank you for your prompt attention to this matter.
          </td>
      </tr>
      <tr>
          <td style="padding: 15px; color: #383C51;">
              Thanks & Regards,
          </td>
      </tr>
      <tr>
<td style="padding-left: 17px; width: 550px;fonts">
  <b style="color: #383c51;">Revathy A – Manager - HRD.</b> <br>
  <img src=" #companyLogo# " alt="openwave logo">
  <br>
  <b style="color: #383c51;">Openwave Computing Services (P) Ltd</b> <br>
</td>
</tr>
  </table>
</div>`;

export const TIMESHEET_LOCKED = ` 
    <div style="width: 100%; background-color: #f6f6f6; padding: 20px 0; display: flex; justify-content: center;">
        <table style="background-color: white; margin-left: auto; margin-right: auto; width: 600px; border: 1px solid #ddd; border-spacing: 0; font-family: Arial, sans-serif;width: 100%;">
            <tr>
                <td style="text-align: center; padding: 20px;height:250px">
                    <img src="#Image#" alt="Image File" style="height: 100%;" />
                </td>
            </tr>
            <tr>
                <td style="padding-left: 15px; color: #383C51; padding-top: 12px;">
                    Dear #Name#,
                </td>
            </tr>
            <tr>
                <td style="padding-left: 15px; color: #383C51; padding-top: 12px;">
                    Good Day!,
                </td>
            </tr>
            <tr>
                <td style="padding-left: 15px; padding-top: 12px; color: #383C51; width: 600px;">
                    We regret to inform you that your timesheet has been locked as it was not submitted by the required deadline.
                </td>
            </tr>
            <tr>
                <td style="padding-left: 15px; width: 550px; padding-top: 12px; color: #383C51; padding-bottom: 8px;">
                    Details:
                </td>
            </tr>
            <tr>
                <td style="padding-left: 15px; color: #383C51;">
                    Locked Date: #Date#
                </td>
            </tr>
            <tr>
                <td style="padding-left: 15px; color: #383C51;">
                    Reason: Failure to submit your timesheet.
                </td>
            </tr>
            <tr>
                <td style="padding-left: 15px; color: #383C51;">
                    If you need to unlock your timesheet or have any questions regarding this issue, please contact your Reporting authority or HR Team.
                </td>
            </tr>
            <tr>
                <td style="padding-left: 15px; padding-top: 12px; color: #383C51;">
                    Thank you for your attention to this matter.
                </td>
            </tr>
            <tr>
                <td style="padding-left: 15px; width: 550px; padding-top: 12px; color: #383C51;">
                    Thanks & Regards,
                </td>
            </tr>
           <tr>
      <td style="padding-left: 17px; width: 550px;fonts">
        <b style="color: #383c51;">Revathy A – Manager - HRD.</b> <br>
        <img src=" #companyLogo# " alt="openwave logo">
        <br>
        <b style="color: #383c51;">Openwave Computing Services (P) Ltd</b> <br>
      </td>
    </tr>
        </table>
    </div>
`;

export const USER_CREATION = `<div style=" background-color: #f6f6f6; padding: 20px 0; display: flex; justify-content: center;">
  <table style="background-color: white; margin-left: auto; margin-right: auto; width: 600px; border: 1px solid #ddd; border-spacing: 0; font-family: Arial, sans-serif;width: 100%;">
      <tr>
          <td style="text-align: center; padding: 20px;height: 180px;">
              <img src="#Image#" alt="Image File" style=" height: 100%;width:100%" />
          </td>
      </tr>
      <tr>
          <td style="padding-left: 15px; color: #383C51; padding-top: 12px;">
              Dear #Name#,
          </td>
      </tr>
      <tr>
          <td style="padding-left: 15px; color: #383C51; padding-top: 12px;">
              Good day!
          </td>
      </tr>
      <tr>
          <td style="padding-left: 15px; padding-top: 12px; color: #383C51; width: 600px;">
              We are pleased to inform you that your account for <b>Openwave Timesheet</b> has been created. You can now log in and start using the system to manage your timesheets efficiently.
          </td>
      </tr>
      <tr>
          <td style="padding-left: 15px; width: 550px; padding-top: 16px; color: #383C51; padding-bottom: 8px;">
              Your login credentials are as follows:
          </td>
      </tr>
      <tr>
          <td colspan="3" style="padding: 0;">
              <table style="width: 100%;">
                  <tr>
                      <td style="width: 27%; padding-left: 15px; padding-top: 8px;"><b>Email</b></td>
                      <td style="width: 4%;"></td>
                      <td style="padding-top: 5px;">: #Email#</td>
                  </tr>
                  <tr>
                      <td style="width: 28%; padding-left: 15px;"><b>Password</b></td>
                      <td style="width: 3%;"></td>
                      <td>: #Password#</td>
                  </tr>
                  <tr>
                      <td style="width: 28%; padding-left: 15px;"><b>Login URL</b></td>
                      <td style="width: 3%;"></td>
                      <td>: #URL#</td>
                  </tr>
              </table>
          </td>
      </tr>
      <tr>
          <td style="padding-left: 15px; padding-top: 16px; color: #383C51;">
              To ensure the security of your account, we recommend that you change your password after your first login. Here’s a quick guide on how to get started:
          </td>
      </tr>
      <tr>
          <td style="padding-left: 15px; padding-top: 25px; color: #383C51;">
              Log in: Enter your email and password in provided URL.
          </td>
      </tr>
      <tr>
          <td style="padding-left: 15px; width: 550px; padding-top: 16px; color: #383C51;">
              Change Password: Navigate to the account settings section and update your password.
          </td>
      </tr>
      <tr>
          <td style="padding-left: 15px; width: 550px; padding-top: 16px; color: #383C51;">
              Explore: Familiarize yourself with the features available to help you manage your timesheets.
          </td>
      </tr>
      <tr>
          <td style="padding-left: 15px; width: 550px; padding-top: 16px; color: #383C51;">
              If you encounter any issues or have any questions, please do not hesitate to reach out to our support team.
          </td>
      </tr>
      <tr>
          <td style="padding-left: 15px; width: 550px; padding-top: 16px; color: #383C51;">
              We are excited to have you on board and look forward to assisting you in managing your timesheets effectively.
          </td>
      </tr>
      <tr>
          <td style="padding-left: 10px; padding-top: 18px;">
              <div style="border-bottom: 2px solid #f8a013; width: 565px;"></div>
          </td>
      </tr>
      <tr>
          <td style="padding-left: 15px; width: 550px; padding-top: 12px; color: #383C51;">
              Thanks & Regards,
          </td>
      </tr>
      <tr>
<td style="padding-left: 17px; width: 550px;fonts">
  <b style="color: #383c51;">Revathy A – Manager - HRD.</b> <br>
  <img src=" #companyLogo# " alt="openwave logo">
  <br>
  <b style="color: #383c51;">Openwave Computing Services (P) Ltd</b> <br>
</td>
</tr>
  </table>
</div>`;

export const LEAVE_SUBMISSION = `<div>
  <table
    style="
      background-color: white;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    "
  >
    <tr>
      <td style="text-align: center; padding: 20px; height: 250px">
        <img src="#Image#" alt="Image File" style="height: 100%" />
      </td>
    </tr>
    <tr>
      <td style="padding-left: 15px; padding-top: 8px; color: #383c51">
        Dear Sir/Mam,
      </td>
    </tr>
    <tr>
      <td style="padding-left: 15px; padding-top: 8px; color: #383c51">
        <b>#UserName#</b> has applied for leave, and the details are below.
      </td>
    </tr>
    <tr>
      <td style="padding-left: 15px; padding-top: 8px; color: #383c51">
        <b>Leave details:</b>
      </td>
    </tr>
    <tr>
      <td colspan="3" style="padding: 0">
        <table style="width: 100%">
          <tr>
            <td
              style="
                width: 28%;
                padding-left: 15px;
                padding-top: 5px;
                color: #383c51;
              "
            >
              Number of days
            </td>
            <td style="width: 2%">:</td>
            <td style="padding-top: 5px; color: #383c51">#Days#</td>
          </tr>
          <tr>
            <td style="width: 28%; padding-left: 15px; color: #383c51">
              Requested Date
            </td>
            <td style="width: 2%">:</td>
            <td style="color: #383c51">#Date#</td>
          </tr>
          <tr>
            <td
              style="
                width: 28%;
                padding-left: 15px;
                color: #383c51;
                vertical-align: top;
              "
            >
              Reason
            </td>
            <td style="width: 2%; vertical-align: top">:</td>
            <td
              style="color: #383c51; vertical-align: top; text-align: justify"
            >
              #Reason#
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td style="padding-left: 15px; padding-top: 10px; color: #383c51">
        For more details, please check the Timesheet application.
      </td>
    </tr>
    <tr>
      <td
        style="
          padding-left: 15px;
          width: 550px;
          padding-top: 15px;
          color: #383c51;
        "
      >
        Thanks & Regards,
      </td>
    </tr>
    <tr>
      <td style="padding-left: 17px; width: 550px">
        <img
          src="#companyLogo#"
          alt="Openwave logo"
        />
        <br />
        <b style="color: #383c51; padding-top: 10px; display: block">
          #CompanyName#
        </b>
      </td>
    </tr>
  </table>
</div>
`;

export const LEAVE_APPROVAL = `<div>
  <table style="background-color: white; margin-left: auto; margin-right: auto;width: 100%;">
    <tr>
      <td style="text-align: center; padding: 20px; height: 250px">
        <img src="#Image#" alt="Image File" style=" height: 100%;" />
      </td>
    </tr>
    <tr>
      <td style="padding-left: 15px; padding-top: 8px; color: #383c51"> Dear #Name# </td>
    </tr>
    <tr>
      <td style="
          padding: 8px 15px 0 15px;
          color: #383c51;
          line-height: 20px;
          word-spacing: 2px;
        "> Your leave request on #Date# has been approved. <br /> Please check the Timesheet Application for more details. </td>
    </tr>
    <tr>
      <td style="
          padding-left: 15px;
          width: 550px;
          padding-top: 12px;
          color: #383c51;
        "> Thanks & Regards, </td>
    </tr>
    <tr>
      <td style="padding-left: 15px; color: #383c51">#ApproveName# <br /><br /></td>
    </tr>
    <tr>
      <td style="padding-left: 17px; width: 550px">
        <img src="#companyLogo#" alt="Openwave logo" />
        <br />
        <b style="color: #383c51; padding-top: 20px; display: block;"> #CompanyName# </b>
      </td>
    </tr>
  </table>
</div>
`;

export const LEAVE_REJECTION = `<div>
  <table style="background-color: white; margin-left: auto; margin-right: auto;width: 100%">
    <tr>
      <td style="text-align: center; padding: 20px;height: 250px;">
        <img src="#Image#" alt="Image File" style="height: 100%;" />
      </td>
    </tr>
    <tr>
      <td style="padding-left: 15px; padding-top: 8px; color: #383c51"> Dear #Name# </td>
    </tr>
    <tr>
      <td style="
          padding: 8px 15px 0 15px;
          color: #383c51;
          line-height: 20px;
          word-spacing: 2px;
        "> Your leave request on #Date# has been rejected. <br /> Please check the Timesheet Application for more details. </td>
    </tr>
    <tr>
      <td style="
          padding-left: 15px;
          width: 550px;
          padding-top: 12px;
          color: #383c51;
        "> Thanks & Regards, </td>
    </tr>
    <tr>
      <td style="padding-left: 15px; color: #383c51">#ApproveName# <br /><br /></td>
    </tr>
    <tr>
      <td style="padding-left: 17px; width: 550px">
        <img src="#companyLogo#" alt="Openwave logo"  />
        <br />
        <b style="color: #383c51; padding-top: 20px; display: block;"> #CompanyName# </b>
      </td>
    </tr>
  </table>
</div>
`;

export const MONTH_APPROVAL = `<div>
  <table style="background-color: white; margin-left: auto; margin-right: auto;width: 100%;">
    <tr>
      <td style="text-align: center; padding: 20px;height: 250px;">
        <img src="#Image#" alt="Image File" style="height: 100%;" />
      </td>
    </tr>
    <tr>
      <td style="padding-left: 15px; padding-top: 8px; color: #383c51"> Dear #Name# </td>
    </tr>
    <tr>
      <td style="
          padding: 8px 15px 0 15px;
          color: #383c51;
          line-height: 20px;
          word-spacing: 2px;
        "> Your #Month# month timesheet has been approved. </td>
    </tr>
    <tr>
      <td style="
          padding-left: 15px;
          width: 550px;
          padding-top: 12px;
          color: #383c51;
        "> Thanks & Regards, </td>
    </tr>
    <tr>
      <td style="padding-left: 15px; color: #383c51">#ApproveName# <br /><br /></td>
    </tr>
    <tr>
      <td style="padding-left: 17px; width: 550px">
        <img src="#companyLogo#" alt="Openwave logo"  />
        <br />
        <b style="color: #383c51; padding-top: 20px; display: block;"> #CompanyName# </b>
      </td>
    </tr>
  </table>
</div>
`;


export const PERMISSION_SUBMISSION = `
<div>
  <table
    style="
      background-color: white;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    "
  >
    <tr>
      <td style="text-align: center; padding: 20px; height: 250px">
        <img src="#Image#" alt="Image File" style="height: 100%" />
      </td>
    </tr>
    <tr>
      <td style="padding-left: 15px; padding-top: 8px; color: #383c51">
        Dear Sir/Mam,
      </td>
    </tr>
    <tr>
      <td style="padding-left: 15px; padding-top: 8px; color: #383c51">
        <b>#UserName#</b> has applied for Permission, and the details are below.
      </td>
    </tr>
    <tr>
      <td style="padding-left: 15px; padding-top: 8px; color: #383c51">
        <b>Permission details:</b>
      </td>
    </tr>
    <tr>
      <td colspan="3" style="padding: 0">
        <table style="width: 100%">
          <tr>
            <td
              style="
                width: 28%;
                padding-left: 15px;
                padding-top: 5px;
                color: #383c51;
              "
            >
              Date
            </td>
            <td style="width: 2%">:</td>
            <td style="padding-top: 5px; color: #383c51">#Date#</td>
          </tr>

          <tr>
            <td style="width: 28%; padding-left: 15px; color: #383c51">
              Permission Time
            </td>
            <td style="width: 2%">:</td>
            <td style="color: #383c51">#Time#</td>
          </tr>
          <tr>
            <td style="width: 28%; padding-left: 15px; color: #383c51">
              Total Hours
            </td>
            <td style="width: 2%">:</td>
            <td style="color: #383c51">#TotalHours#</td>
          </tr>
          <tr>
            <td
              style="
                width: 28%;
                padding-left: 15px;
                color: #383c51;
                vertical-align: top;
              "
            >
              Reason
            </td>
            <td style="width: 2%; vertical-align: top">:</td>
            <td
              style="color: #383c51; vertical-align: top; text-align: justify"
            >
              #Reason#
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td style="padding-left: 15px; padding-top: 10px; color: #383c51">
        For more details, please check the Timesheet application.
      </td>
    </tr>
    <tr>
      <td
        style="
          padding-left: 15px;
          width: 550px;
          padding-top: 15px;
          color: #383c51;
        "
      >
        Thanks & Regards,
      </td>
    </tr>
    <tr>
      <td style="padding-left: 17px; width: 550px">
        <img src="#companyLogo#" alt="Openwave logo" />
        <br />
        <b style="color: #383c51; padding-top: 10px; display: block">
          #CompanyName#
        </b>
      </td>
    </tr>
  </table>
</div>
`;
export const PERMISSION_APPROVAL = `
<div>
  <table
    style="
      background-color: white;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    "
  >
    <tr>
      <td style="text-align: center; padding: 20px; height: 250px">
        <img src="#Image#" alt="Image File" style="height: 100%" />
      </td>
    </tr>
    <tr>
      <td style="padding-left: 15px; padding-top: 8px; color: #383c51">
        Dear #Name#
      </td>
    </tr>
    <tr>
      <td
        style="
          padding: 8px 15px 0 15px;
          color: #383c51;
          line-height: 20px;
          word-spacing: 2px;
        "
      >
        Your Permission request on #Date# has been approved. <br />
        Please check the Timesheet Application for more details.
      </td>
    </tr>
    <tr>
      <td
        style="
          padding-left: 15px;
          width: 550px;
          padding-top: 12px;
          color: #383c51;
        "
      >
        Thanks & Regards,
      </td>
    </tr>
    <tr>
      <td style="padding-left: 15px; color: #383c51">
        #ApproveName# <br /><br />
      </td>
    </tr>
    <tr>
      <td style="padding-left: 17px; width: 550px">
        <img src="#companyLogo#" alt="Openwave logo" />
        <br />
        <b style="color: #383c51; padding-top: 20px; display: block">
          #CompanyName#
        </b>
      </td>
    </tr>
  </table>
</div>
`;
export const PERMISSION_REJECTION = `
<div>
  <table
    style="
      background-color: white;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    "
  >
    <tr>
      <td style="text-align: center; padding: 20px; height: 250px">
        <img src="#Image#" alt="Image File" style="height: 100%" />
      </td>
    </tr>
    <tr>
      <td style="padding-left: 15px; padding-top: 8px; color: #383c51">
        Dear #Name#
      </td>
    </tr>
    <tr>
      <td
        style="
          padding: 8px 15px 0 15px;
          color: #383c51;
          line-height: 20px;
          word-spacing: 2px;
        "
      >
        Your Permission request on #Date# has been rejected. <br />
        Please check the Timesheet Application for more details.
      </td>
    </tr>
    <tr>
      <td
        style="
          padding-left: 15px;
          width: 550px;
          padding-top: 12px;
          color: #383c51;
        "
      >
        Thanks & Regards,
      </td>
    </tr>
    <tr>
      <td style="padding-left: 15px; color: #383c51">
        #ApproveName# <br /><br />
      </td>
    </tr>
    <tr>
      <td style="padding-left: 17px; width: 550px">
        <img src="#companyLogo#" alt="Openwave logo" />
        <br />
        <b style="color: #383c51; padding-top: 20px; display: block">
          #CompanyName#
        </b>
      </td>
    </tr>
  </table>
</div>
`;
