import React from 'react';
import { FiEdit } from 'react-icons/fi';
import { IoEyeOutline } from 'react-icons/io5';
import { MdDeleteOutline, MdOutlineComment } from 'react-icons/md';

const ActionButton = ({ item, handleClick, hideBtn }) => {
  return (
    <div className="d-flex justify-content-start ">
      <div className="">
        {' '}
        {/* <button
          className="icon-hover btn m-0 p-0"
          onClick={() => handleClick(item, 'View')}
          title="View"
        >
          <IoEyeOutline size={18} />
        </button> */}
      </div>
      <div>
        {hideBtn?.edit && (
          <button
            className="icon-hover btn p-0"
            onClick={() => handleClick(item, 'Edit')}
            title="Edit"
          >
            <FiEdit size={16} />
          </button>
        )}
      </div>
      <div>
        {' '}
        {hideBtn?.comment && (
          <button
            className="icon-hover btn p-0"
            onClick={() => handleClick(item, 'Comment')}
            title="Comment"
          >
            <MdOutlineComment size={18} />
          </button>
        )}
      </div>
      <div>
        {' '}
        {hideBtn?.delete && (
          <button
            className="icon-hover btn p-0"
            onClick={() => handleClick(item, 'Delete')}
            title="Delete"
          >
            <MdDeleteOutline size={20} />
          </button>
        )}
      </div>
    </div>
  );
};

export default ActionButton;
