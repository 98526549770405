import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { FiEdit } from 'react-icons/fi';
import { MdDeleteOutline } from 'react-icons/md';

import { NORECORD, PaginationRange } from '../../constant/constant';
import TmPagination from '../ReusableComponents/Pagination';
import ShowStatus from '../ReusableComponents/ShowStatus';

const inputHeader = ['action', 'checkbox'];

const UserRoleTable = (props) => {
  const { rows, columns, toggleModal, custom, handleDelete, buttonPermission } =
    props;
  const [pagination, setPagination] = useState([]);
  let columnKey = [];
  let customKey = [...custom];
  if (rows.length > 0) {
    columnKey = columns.map((item) => item.field);
  }

  useEffect(() => {
    PaginationRange.setPageSize(10);
    handlePagination(1, rows);
  }, [rows]);

  const handlePagination = async (data, current = []) => {
    let endRange = data * Number(PaginationRange.endRange) - 1;
    let startRange =
      data * Number(PaginationRange.startRange) -
      Number(PaginationRange.initalStart);
    const check = current.length ? current : rows;
    setPagination(
      check.filter((_, index) => index <= endRange && index >= startRange),
    );
  };

  const onShowSizeChange = (current, pageSize) => {
    PaginationRange.setPageSize(pageSize);
    handlePagination(current, rows);
  };

  return (
    <div className="icon-btn">
      <Table className="table-header-custom border" responsive>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th
                className={`${
                  inputHeader.includes(column.field) ? 'text-center' : null
                }`}
                key={index + 1}
              >
                {column.headerName}
              </th>
            ))}
            {(buttonPermission == undefined ||
              buttonPermission?.edit ||
              buttonPermission?.delete) &&
              customKey.includes('action') && <th>ACTION</th>}
          </tr>
        </thead>
        <tbody>
          {pagination.length <= 0 && (
            <tr>
              <td colSpan={100} className="text-center">
                {NORECORD}
              </td>
            </tr>
          )}

          {pagination.length > 0 &&
            pagination?.map((row, idx) => (
              <tr key={idx + 1}>
                {columnKey.map((item, index) => (
                  <td key={index + 1}>
                    {item !== 'status' ? (
                      <span title={row[item]}> {row[item] || '--'}</span>
                    ) : (
                      <ShowStatus status={row[item] ? 'Active' : 'InActive'} />
                    )}
                  </td>
                  // <td key={index + 1}>
                  //   {item !== 'status' ? (
                  //     <span title={row[item]}> {row[item]}</span>
                  //   ) : row[item] ? (
                  //     <span className="status-completed">Active</span>
                  //   ) : (
                  //     <span className="status-inprogress">InActive</span>
                  //   )}
                  // </td>
                ))}

                {(buttonPermission == undefined ||
                  buttonPermission?.edit ||
                  buttonPermission?.delete) &&
                  customKey.includes('action') && (
                    <td className="antdBtn">
                      {(buttonPermission === undefined ||
                        buttonPermission?.edit) &&
                        row?.id !== 1 &&
                        true && (
                          <button
                            title="Edit"
                            className="btn p-0 m-0 icon-hover"
                            onClick={() => toggleModal('Edit', row)}
                          >
                            {<FiEdit />}
                          </button>
                        )}

                      {(buttonPermission === undefined ||
                        buttonPermission?.delete) &&
                        row?.id !== 1 &&
                        row?.id !== 2 && (
                          <button
                            title="Delete"
                            className="btn p-0 m-0 icon-hover"
                          >
                            <MdDeleteOutline
                              size={18}
                              onClick={() => handleDelete(row.id)}
                            />
                          </button>
                        )}
                    </td>
                  )}
              </tr>
            ))}
        </tbody>
      </Table>
      <div>
        <TmPagination
          onShowSizeChange={onShowSizeChange}
          total={rows.length}
          handlePagination={handlePagination}
        />
      </div>
    </div>
  );
};

export default UserRoleTable;
