import { Popover } from 'antd';
import React from 'react';

const ListMembers = ({ members = [] }) => {
  const content = (
    <div style={{ overflowY: 'auto', maxHeight: 250 }} className="pe-1">
      {members.map((item, indx) => (
        <div
          key={indx}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {item}
        </div>
      ))}
    </div>
  );

  return (
    <>
      <div className="d-flex align-items-center ">
        <div className=" d-flex align-items-center ">
          {members.map(
            (element, indx) =>
              (indx === 0 || indx === 1) && (
                <span
                  key={indx + 99}
                  className=" alert alert-primary mb-0 p-1 py-0 me-1 d-inline-block"
                  title={element}
                  style={{
                    width: `${members?.length === 1 ? 'auto' : '48px'}`,
                    maxWidth: `100px`,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflowX: 'hidden',
                  }}
                >
                  {element}
                </span>
              ),
          )}
        </div>
        <div>
          {members.length > 2 && (
            <>
              <Popover content={content} placement="topRight">
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="text-decoration-underline text-primary tmPointer"
                >
                  See More
                </span>
              </Popover>
            </>
          )}
        </div>
        {members.length <= 0 && '--'}
      </div>
    </>
  );
};

export default ListMembers;
