import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  TimePicker,
  InputNumber,
} from 'antd';
import enUS from 'antd/es/locale/en_US';
import { useForm, Controller } from 'react-hook-form';
import TextArea from 'antd/es/input/TextArea';

import { Label, Row, Col } from 'reactstrap';
import dayjs from 'dayjs';
import {
  DateFormat,
  LEAVETYPE,
  PERMISSION_PAGE_ROUTES,
} from '../../constant/constant';
import Mandatory from '../ReusableComponents/Mandatory';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import { LeaveManageApi } from '../../Api/LeaveManageApi';
import {
  toastError,
  toastInfo,
  toastSuccess,
  toastWarn,
} from '../Toast/ToastComponent';
import { useAuth } from '../../Context/AuthContext';
import { useLocation } from 'react-router-dom';
import { Permissions } from '../../Api/Permisisons';
const CommentModal = ({
  modalData,
  getLeaveManageList,
  toggleModal,
  isPermission,
}) => {
  const { pathname } = useLocation();

  const [isPermissionComp, setIsPermissionComp] = useState(false);

  const [form] = Form.useForm();

  const { authState } = useAuth();

  const { Option } = Select;

  const [isModalOpen, setModalOpen] = useState(false);

  const toggle = () => {
    setModalOpen(!isModalOpen);
  };

  const onSubmit = async (data) => {
    let newData = { ...data, ...modalData, user_id: authState.id };

    try {
      const response =
        PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1]) || isPermission
          ? await Permissions.edit(newData)
          : await LeaveManageApi.edit(newData);
      if (response.data.success) {
        toastSuccess('Comment has been added successfully.');
        getLeaveManageList();
        form.resetFields();
        toggleModal();
      } else {
        toastInfo(response.data.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  const getIsPermission = () => {
    try {
      setIsPermissionComp(
        PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1])
          ? true
          : isPermission,
      );
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  const customizeRequiredMark = (label, { required }) => (
    <>
      {label}
      {required && <Mandatory />}
    </>
  );

  useEffect(() => {
    getIsPermission();
  }, []);

  return (
    <>
      <div>
        <Form
          layout="vertical"
          form={form}
          onFinish={onSubmit}
          requiredMark={customizeRequiredMark}
        >
          <Row>
            <Col>
              <Form.Item
                name="comment"
                label="Comment"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input.TextArea rows={4} placeholder="Write here" />
              </Form.Item>
              <div className="text-center antdBtn">
                <Button
                  onClick={() => toggleModal()}
                  type="default"
                  htmlType="button"
                >
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default CommentModal;
