import React, { useState, useEffect } from 'react';
import { Badge, Button } from 'antd';
import { GoBell } from 'react-icons/go';
import NotificationBody from './NotificationBody';
import TmDrawer from '../ReusableComponents/TmDrawer';
import { VscBell } from 'react-icons/vsc';
import { toastError } from '../Toast/ToastComponent';
import NotificationAPI from '../../Api/notificationsAPI.js';
import { useAuth } from '../../Context/AuthContext.js';

const NotificationHandler = () => {
  const { authState } = useAuth();

  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [notificationArr, setNotificationArr] = useState({
    data: [],
    unRead: undefined,
  });

  const getNotifications = async () => {
    try {
      const response = await NotificationAPI.get({ id: authState?.id });
      if (response?.data?.success) {
        setNotificationArr((prev) => ({
          ...prev,
          data: response?.data?.result?.data,
          unRead: response?.data?.result?.unRead,
        }));
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <>
      <Button htmlType="button" className="btn me-3 p-0" onClick={toggleDrawer}>
        <GoBell size={25} style={{ float: 'left' }}></GoBell>
        {notificationArr.unRead ? (
          <Badge
            count={notificationArr.unRead}
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              transform: 'translate(50%, 0%)',
            }}
          ></Badge>
        ) : (
          <></>
        )}
      </Button>
      <TmDrawer
        drawerbody={
          <NotificationBody
            toggleDrawer={toggleDrawer}
            notificationArr={notificationArr?.data}
            getNotifications={getNotifications}
            authState={authState}
          />
        }
        title="Notification"
        toggleDrawer={toggleDrawer}
        open={open}
      />
    </>
  );
};

export default NotificationHandler;
