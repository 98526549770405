import { Button } from 'antd';
import React from 'react';
import style from '../../../Styles/SampleModal.module.css';
import {
  toastError,
  toastInfo,
  toastSuccess,
} from '../../Toast/ToastComponent';
import { TimeSheetApi } from '../../../Api/TimeSheetApi';
import { months } from 'moment';

const UnlockAllPopup = ({
  toggleModal,
  userData,
  filterValue,
  getLogsList,
  getAttendanceReport,
}) => {
  const handleUnlock = async () => {
    try {
      let body = {
        month: filterValue?.month,
        year: filterValue?.year,
        user_id: userData?.employee_id,
      };
      const response = await TimeSheetApi.unlockAll(body);
      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        await getLogsList();
        await getAttendanceReport();
        toggleModal();
      } else {
        toastInfo(response?.data?.message);
        await getLogsList();
        await getAttendanceReport();
        toggleModal();
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };
  return (
    <div>
      <div className="mt-2 fs-6">Are you sure you want to unlock all?</div>

      <div className="antdBtn mt-4 text-center">
        <Button type="default" htmlType="button" onClick={toggleModal}>
          Cancel
        </Button>
        <Button onClick={handleUnlock} type="primary" htmlType="submit">
          Unlock
        </Button>
      </div>
    </div>
  );
};

export default UnlockAllPopup;
