import React, { useState, useEffect, Fragment } from 'react';
import { MdAdd, MdAttachMoney, MdMoneyOff } from 'react-icons/md';
import TimeSheetAccordian from './TimeSheetAccordian';
import ModalComponent from '../../ReusableComponents/ModalComponent';
import { Button, Checkbox, Form } from 'antd';
import { LuDownload } from 'react-icons/lu';
import TmFilter from '../../ReusableComponents/TmFilter';
import {
  LEAVETYPE,
  MODULE_STATUS,
  MONTHS,
  SORTING,
  YEARS,
} from '../../../constant/constant';

import AntdModalComp from '../../ReusableComponents/AntdModalComp';
import dayjs from 'dayjs';
import { TimeSheetApi } from '../../../Api/TimeSheetApi';
import { toastError, toastInfo, toastWarn } from '../../Toast/ToastComponent';
import { useAuth } from '../../../Context/AuthContext';
import Loader from '../../ReusableComponents/Loader';
import AddLogBody from './AddLogBody';
import AddLogBdyDnD from './AddLogBdyDnD';
import {
  generateCurrentMonth,
  generateCurrentYear,
  generateDays,
  generateYears,
} from '../../../ReusableFn/reusableFn';
import NoDataFound from '../../ReusableComponents/NoDataFound';
import { downloadExcel } from '../../ReusableComponents/downloadExcel';
import style from '../../../Styles/TimeSheet.module.css';
import {
  TbCalendarCancel,
  TbCalendarCheck,
  TbCalendarClock,
  TbSquareRoundedLetterT,
  TbUserExclamation,
} from 'react-icons/tb';
import { PiClockUserBold } from 'react-icons/pi';
import AttendanceReport from '../../../Api/attendanceReport';
import { ProjectApi } from '../../../Api/ProjectApi';
import { useParams } from 'react-router-dom';

let isEdit = {
  title: 'Add',
  edit: false,
  id: -1,
};

function TimeSheet() {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [filterValue, setFilterValue] = useState({
    month: generateCurrentMonth(),
    year: generateCurrentYear(),
    order: SORTING[0].value,
    limit: 31,
    project_search: [],
  });

  const [timeLog, setTimeLog] = useState({
    timesheetData: [],
    timesheet_details: {},
  });
  const { authState } = useAuth();
  const [form] = Form.useForm();

  const [monthReport, setMonthReport] = useState([]);
  const [reportData, setReportData] = useState(undefined);
  const filterArray = [
    {
      options: MONTHS,
      optionFilterProp: 'label',
      placeholder: 'Month',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: generateCurrentMonth(),
      controlName: 'month',
      allowClear: false,
      input: 'select',
    },
    {
      options: generateYears() || [],
      optionFilterProp: 'label',
      placeholder: 'Year',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: generateCurrentYear(),
      controlName: 'year',
      allowClear: false,
      input: 'select',
    },
    {
      options: SORTING,
      optionFilterProp: 'label',
      placeholder: 'Sorting',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: SORTING[0].value,
      controlName: 'order',
      allowClear: false,
      input: 'select',
    },
    {
      options: generateDays(),
      optionFilterProp: 'Limit',
      placeholder: 'limit',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: 31,
      controlName: 'limit',
      allowClear: true,
      input: 'select',
    },
    {
      options: projectList,
      optionFilterProp: 'label',
      placeholder: 'Project',
      order: 1,
      col: 2,
      mode: 'multiple',
      maxTagCount: 1,
      defaultValue: null,
      controlName: 'project_search',
      allowClear: true,
      input: 'select',
    },
  ];

  const handleFilterChange = (obj, type) => {
    setFilterValue((pre) => ({ ...obj }));
  };

  const getLogsList = async () => {
    let reqData = {
      user_id: authState.id,
      ...filterValue,
    };
    try {
      const response = await TimeSheetApi.getUserLogs(reqData);

      const res = response?.data;
      if (res?.success) {
        setLoading(true);
        setTimeLog({
          timesheetData: res?.result?.timesheetData || [],
          timesheet_details: res?.result?.timesheet_details || {},
        });
        setMonthReport(res?.result?.monthReport);
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  const handleDownload = async () => {
    let downloadData = {
      fileName: 'TimeSheet.xlsx',
      sheetName: 'June-2024',
      data: [],
    };
    let reqData = {
      user_id: authState.id,
      ...filterValue,
    };
    reqData.order = '';
    reqData.limit = '';
    try {
      const response = await TimeSheetApi.downloadData({
        user_id: authState.id,
        ...filterValue,
      });
      const res = response?.data;
      if (res.success) {
        if (res?.result.length <= 0) {
          toastInfo('No Data Found');
          return;
        }
        let findMonth = res?.result[0]['date'];
        const month =
          new Date(dayjs(findMonth)).toLocaleString('en-US', {
            month: 'long',
          }) || '';
        const year = new Date(dayjs(findMonth)).getFullYear() || '';
        downloadData['fileName'] = `${
          authState.first_name || ''
        }_${month}_${year}_Timesheet.xlsx`;
        downloadData['sheetName'] = `${month}-${year}` || 'sheet 1';
        downloadData['data'] = res?.result || [];
        downloadExcel(downloadData);
      } else {
        toastWarn(res.message);
      }
    } catch (err) {
      toastError(err.message);
    }
  };

  const handleClick = (type, id) => {
    switch (type) {
      case 'edit':
        handleEdit(id);
        break;

      default:
        break;
    }
  };

  const handleEdit = (id) => {
    isEdit = { title: 'Edit', edit: true, id: id };
    toggleModal();
  };
  const handleAdd = () => {
    isEdit = { title: 'Add', edit: false, id: -1 };
    toggleModal();
  };

  const toggleModal = (type = 'nochange') => {
    if (type === 'changes') {
      getLogsList();
    }
    setModalOpen(!modalOpen);
  };

  const monthApproveStatus = async (reportObj) => {
    const { is_approved, first_approvers_status, second_approvers_status } =
      reportObj;

    const status =
      first_approvers_status &&
      is_approved === null &&
      second_approvers_status === null
        ? 'Approved by First Approver'
        : second_approvers_status && is_approved
        ? 'Approved'
        : !first_approvers_status && !is_approved && !second_approvers_status
        ? 'Pending'
        : 'Pending';

    setReportData(status);
  };

  const getAttendanceReport = async () => {
    try {
      let body = {
        month: filterValue?.month,
        year: filterValue?.year,
        user_id: authState?.id,
        showAll: true,
      };
      const response = await AttendanceReport.get(body);
      if (response?.data?.success) {
        await monthApproveStatus(
          response?.data?.result.length ? response?.data?.result[0] : {},
        );
      } else {
        toastInfo(response?.data?.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };
  const getDropDown = async () => {
    try {
      const response = await ProjectApi.dropDown({
        id: authState?.id,
        userRoleID: authState?.userRoleID,
        showOthers: true,
      });

      if (response?.data?.success) {
        setProjectList(response.data?.result);
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };
  useEffect(() => {
    getLogsList();
    getAttendanceReport();
    getDropDown();
  }, [filterValue]);

  return (
    <div>
      {/* <div className="d-flex justify-content-center">
        <div
          style={{ background: '#EAF2EF', color: '#4B7E69', width: '180px' }}
          className="border h4 text-center rounded-pill p-2"
        >
          Approved
        </div>
      </div> */}
      <div className="row m-lg-0 align-items-lg-center ">
        <div className="col-lg-6 ps-lg-0">
          <div className="d-flex align-items -lg-center">
            <div>
              <h5 className="text-nowrap me-2">My Timesheet</h5>
            </div>
          </div>
        </div>
        <div className="col-lg-6  px-lg-0 ps-lg-0">
          {' '}
          <div className="d-flex mt-lg-4 mt-1 justify-content-lg-end">
            <div className="h5 mt-1">Month Status : </div>
            <div
              style={{
                color:
                  reportData == 'Pending by First Approver' ||
                  reportData == 'Pending'
                    ? '#DDA500'
                    : '#4B7E69',
              }}
              className=" h5 text-center  p-1 ms-1 "
            >
              <span>{reportData}</span>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <>
          <div className="row row-cols-lg-8  mb-2">
            <div className="col-12 col-lg   ">
              <div className="border bg-white d-flex  p-1 px-2 rounded-1 ">
                <div className=" col-lg-10 col-8 ">
                  <div className="firstBoxx">
                    <p className={`m-0 ${style.addcardicon}`}>
                      <b className="primaryColor">
                        {timeLog?.timesheet_details?.total_hours ?? 0}
                      </b>
                    </p>
                  </div>
                  <div className="secondBoxx">
                    <p className={`mb-0 mt-2 fw-semibold ${style.childcolor}`}>
                      Total hours Worked
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 ps-5  ps-lg-0 d-flex align-items-center justify-content-center">
                  <div className={`m-0  ${style.circleRadius}`}>
                    {' '}
                    <span className={` ${style.icontranslate}`}>
                      <TbSquareRoundedLetterT size={20} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg  ps-lg-0  ">
              <div className="border mt-2 mt-lg-0 bg-white d-flex  p-1 px-2 rounded-1 ">
                <div className=" col-lg-10 col-8 ">
                  <div className="firstBoxx">
                    <p className={`m-0 ${style.addcardicon}`}>
                      <b className="primaryColor">
                        {timeLog?.timesheet_details?.billable_hours ?? 0}
                      </b>
                    </p>
                  </div>
                  <div className="secondBoxx">
                    <p className={`mb-0 mt-2 fw-semibold ${style.childcolor}`}>
                      Billable Hours
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 ps-5 ps-lg-0 d-flex align-items-center justify-content-center">
                  <div className={`m-0 ${style.circleRadius}`}>
                    {' '}
                    <span className={` ${style.icontranslate}`}>
                      <MdAttachMoney size={20} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg  ps-lg-0 ">
              <div className="border mt-2 mt-lg-0 bg-white d-flex  p-1 px-2 rounded-1 ">
                <div className=" col-lg-10 col-8 ">
                  <div className="firstBoxx">
                    <p className={`m-0 ${style.addcardicon}`}>
                      <b className="primaryColor">
                        {timeLog?.timesheet_details?.non_billable_hours ?? 0}
                      </b>
                    </p>
                  </div>
                  <div className="secondBoxx">
                    <p className={`mb-0 mt-2 fw-semibold ${style.childcolor}`}>
                      Non Billable Hours
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 ps-5 ps-lg-0 d-flex align-items-center justify-content-center">
                  <div className={`m-0 ${style.circleRadius}`}>
                    {' '}
                    <span className={` ${style.icontranslate}`}>
                      <MdMoneyOff size={20} />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg  ps-lg-0  ">
              <div className="border mt-2 mt-lg-0 bg-white d-flex  p-1 px-2 rounded-1 ">
                <div className=" col-lg-10 col-8 ">
                  <div className="firstBoxx">
                    <p className={`m-0 ${style.addcardicon}`}>
                      <b className="primaryColor">
                        {timeLog?.timesheet_details?.leaves ?? 0}
                      </b>
                    </p>
                  </div>
                  <div className="secondBoxx">
                    <p className={`mb-0 mt-2 fw-semibold ${style.childcolor}`}>
                      Leave Taken
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 ps-5 ps-lg-0 d-flex align-items-center justify-content-center">
                  <div className={`m-0 ${style.circleRadius}`}>
                    {' '}
                    <span className={` ${style.icontranslate}`}>
                      <TbUserExclamation size={20} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row row-cols-lg-8 mb-2">
            <div className="col-12 col-lg   ">
              <div className="border mt-2 mt-lg-0 bg-white d-flex  p-1 px-2 rounded-1 ">
                <div className=" col-lg-10 col-8 ">
                  <div className="firstBoxx">
                    <p className={`m-0 ${style.addcardicon}`}>
                      <b className="primaryColor">
                        {timeLog?.timesheet_details?.permissions ?? 0}
                      </b>
                    </p>
                  </div>
                  <div className="secondBoxx">
                    <p className={`mb-0 mt-2 fw-semibold ${style.childcolor}`}>
                      Permission Taken
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 ps-5 ps-lg-0 d-flex align-items-center justify-content-center">
                  <div className={`m-0 ${style.circleRadius}`}>
                    {' '}
                    <span className={` ${style.icontranslate}`}>
                      <PiClockUserBold size={20} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg  ps-lg-0  ">
              <div className="border mt-2 mt-lg-0 bg-white d-flex  p-1 px-2 rounded-1 ">
                <div className=" col-lg-10 col-8 ">
                  <div className="firstBoxx">
                    <p className={`m-0 ${style.addcardicon}`}>
                      <b className="primaryColor">
                        {timeLog?.timesheet_details?.pending ?? 0}
                      </b>
                    </p>
                  </div>
                  <div className="secondBoxx">
                    <p className={`mb-0 mt-2 fw-semibold ${style.childcolor}`}>
                      Pending
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 ps-5 ps-lg-0 d-flex align-items-center justify-content-center">
                  <div className={`m-0 ${style.circleRadius}`}>
                    {' '}
                    <span className={` ${style.icontranslate}`}>
                      <TbCalendarClock size={20} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg  ps-lg-0  ">
              <div className="border mt-2 mt-lg-0 bg-white d-flex  p-1 px-2 rounded-1 ">
                <div className=" col-lg-10 col-8 ">
                  <div className="firstBoxx">
                    <p className={`m-0 ${style.addcardicon}`}>
                      <b className="primaryColor">
                        {timeLog?.timesheet_details?.approved ?? 0}
                      </b>
                    </p>
                  </div>
                  <div className="secondBoxx">
                    <p className={`mb-0 mt-2 fw-semibold ${style.childcolor}`}>
                      Approved
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 ps-5 ps-lg-0 ps-lg-0 ps-5 d-flex  align-items-center justify-content-center">
                  <div className={`m-0 ${style.circleRadius}`}>
                    {' '}
                    <span className={` ${style.icontranslate}`}>
                      <TbCalendarCheck size={20} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg  ps-lg-0  ">
              <div className="border mt-2 mt-lg-0 bg-white d-flex  p-1 px-2 rounded-1 ">
                <div className=" col-lg-10 col-8 ">
                  <div className="firstBoxx">
                    <p className={`m-0 ${style.addcardicon}`}>
                      <b className="primaryColor">
                        {timeLog?.timesheet_details?.rejected ?? 0}
                      </b>
                    </p>
                  </div>
                  <div className="secondBoxx">
                    <p className={`mb-0 mt-2 fw-semibold ${style.childcolor}`}>
                      Rejected
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 ps-5 ps-lg-0 d-flex align-items-center justify-content-center">
                  <div className={`m-0 ${style.circleRadius}`}>
                    {' '}
                    <span className={` ${style.icontranslate}`}>
                      <TbCalendarCancel size={20} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" row  mx-0">
            <div className="col-lg-8 d-lg-block d-none  px-0 ">
              <div className="d-flex m-0     ">
                {monthReport?.map((item, idx) => (
                  <p
                    className="border p-1  me-1  ps-2 rounded-1 statusColor"
                    key={idx}
                  >
                    {item.label} <b className="px-1"> {item.value}</b>
                  </p>
                ))}
              </div>
            </div>
            {/* for small screen */}
            <div className="col-lg-8  d-lg-none  px-0 ">
              <div className="d-flex m-0     ">
                <div className=" row m-0 ">
                  {monthReport?.map((item, idx) => (
                    <div className=" col-6 pe-2 px-0" key={idx}>
                      <p className="border ps-1  rounded-1 statusColor">
                        {item.label} <b className=""> {item.value}</b>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-4 px-0   ">
              {' '}
              <div className="d-flex m-0  justify-content-lg-end justify-content-between   ">
                <div className="px-0  pe-2">
                  <Button
                    htmlType="button"
                    title="Download"
                    type="dashed"
                    onClick={handleDownload}
                    icon={<LuDownload size={16} />}
                  >
                    Download
                  </Button>
                </div>
                <div className="px-0">
                  <Button
                    title="Add"
                    type="primary"
                    htmlType="button"
                    icon={<MdAdd size={20} />}
                    onClick={handleAdd}
                  ></Button>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-3 mt-lg-0 ">
            <TmFilter
              filterArray={filterArray}
              handleFilterChange={handleFilterChange}
            />
          </div>
          <div>
            {timeLog?.timesheetData.length > 0 && (
              <TimeSheetAccordian
                timeLog={timeLog.timesheetData}
                handleClick={handleClick}
              />
            )}
            {!timeLog?.timesheetData.length > 0 && <NoDataFound />}
          </div>
          <Fragment>
            <AntdModalComp
              width="90%"
              toggleModal={toggleModal}
              modalOpen={modalOpen}
              modalHeader={<></>}
              modalBody={
                <AddLogBdyDnD toggleModal={toggleModal} isEdit={isEdit} />
              }
              closable={false}
              maskClosable={false}
            />
          </Fragment>
        </>
      )}
      {!loading && <Loader />}
    </div>
  );
}

export default TimeSheet;
