import React from 'react';
import '../../Styles/Login.css';
import { Button, Form, Input } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { toastError, toastInfo, toastSuccess } from '../Toast/ToastComponent';
import LoginApi from '../../Api/LoginApi';
import { DOMAIN_HOST } from '../../config/appConfig';
import { removeAuthInfo } from '../../Context/AuthSessionStorage';
import { useAuth } from '../../Context/AuthContext';

const customizeRequiredMark = (label, { required }) => <>{label}</>;

function ForgetPassword({ handleToggle }) {
  const [form] = Form.useForm();

  const { username, isAuthenticated, login, logout } = useAuth();

  const handleSubmit = async (data) => {
    let url = DOMAIN_HOST + '/' + 'reset_password';
    let reqData = { url, ...data };
    try {
      let response = await LoginApi.resetPassword(reqData);
      if (response?.data?.success) {
        toastSuccess(response.data.message);
        handleToggle();
        removeAuthInfo();
        logout();
      } else {
        toastInfo(response?.data?.message);
        return;
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  return (
    <div className="d-flex flex-column m-0 justify-content-center align-item-center">
      <div className="text-center">
        <h2 className="fw-bold">FORGOT PASSWORD</h2>
        <div>
          <p className="w-50 text-center m-auto">
            Enter valid email to receive instructions to how to reset your
            password
          </p>
        </div>
      </div>
      <div className="mt-4">
        <div className="row justify-content-center">
          <div className="col col-md-8">
            <Form
              name="basic"
              form={form}
              requiredMark={customizeRequiredMark}
              layout="vertical"
              autoComplete="off"
              onFinish={handleSubmit}
            >
              <Form.Item
                label="Email"
                name="email"
                style={{ marginBottom: 35 }}
                rules={[
                  {
                    type: 'email',
                    message: 'Please enter a valid email',
                  },
                  {
                    required: true,
                    message: 'Please enter your email',
                  },
                ]}
              >
                <Input type="email" size="large" placeholder="Enter Email" />
              </Form.Item>

              <Form.Item wrapperCol={{ span: 24 }} style={{ marginBottom: 7 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{ width: '100%' }}
                >
                  Reset password
                </Button>
              </Form.Item>
              <div className="text-end">
                <Link className="linkColor" onClick={handleToggle}>
                  Back to login
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
