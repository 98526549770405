import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import TmPagination from '../../ReusableComponents/Pagination';
import { PaginationRange, NORECORD } from '../../../constant/constant';
import ShowStatus from '../../ReusableComponents/ShowStatus';
import ListMembers from '../../ReusableComponents/ListMembers';
import { IoEyeOutline } from 'react-icons/io5';

const NotificationTable = ({ rows, handleClick }) => {
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    PaginationRange.setPageSize(10);
    handlePagination(1, rows);
  }, [rows]);

  const columns = [
    { field: 'email_type', headerName: ' EVENT TYPE', className: '' },
    // { field: 'serialNo', headerName: 'S.NO', className: 'col-md-auto' },
    { field: 'subject', headerName: 'SUBJECT', className: 'col-md-2' },
    { field: 'to', headerName: 'TO', className: 'col-md-2' },
    { field: 'from', headerName: 'FROM', className: 'col-md-2' },
    { field: 'cc', headerName: 'CC', className: 'col-md-2' },
    { field: 'bcc', headerName: 'BCC', className: 'col-md-2' },
    // { field: 'email_type', headerName: 'EMAIL TYPE', className: '' },
    { field: 'createdAt', headerName: 'CREATED DATE', className: 'col-md-2' },
    { field: 'status', headerName: 'STATUS', className: 'col-md-2' },
    { field: 'action', headerName: 'ACTION', className: 'col-auto' },
  ];

  const inputHeader = ['status'];
  const handlePagination = async (data, current = []) => {
    let endRange = data * Number(PaginationRange.endRange) - 1;
    let startRange =
      data * Number(PaginationRange.startRange) -
      Number(PaginationRange.initalStart);
    const check = current.length ? current : rows;
    setPagination(
      check.filter((_, index) => index <= endRange && index >= startRange),
    );
  };
  const onShowSizeChange = (current, pageSize) => {
    PaginationRange.setPageSize(pageSize);
    handlePagination(current, rows);
  };
 console.log(pagination)
  return (
    <>
      <div>
        <Table responsive>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th
                  className={`${column.className} ${
                    inputHeader.includes(column.field) ? 'text-center' : ''
                  }`}
                  key={index}
                >
                  {column.headerName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {pagination?.length == 0 && (
              <tr>
                <td colSpan={columns.length} className="text-center">
                  {NORECORD}
                </td>
              </tr>
            )}
            {pagination?.length > 0 &&
              pagination?.map((row, index) => (
                <tr
                  key={index}
                  onClick={() => handleClick(row, 'view')}
                  className="hoverTr"
                >
                  <td>
                    {' '}
                    <span>{<ShowStatus status={row?.email_type} />}</span>
                  </td>
                  <td>{row?.subject}</td>

                  <td>
                    <ListMembers members={row?.to || []} />
                  </td>
                  {/* <td>{row?.to}</td> */}
                  <td>{row?.from}</td>
                  <td>
                    <ListMembers members={row?.cc || []} />
                  </td>
                  <td>
                    <ListMembers members={row?.bcc || []} />
                  </td>
                  {/* <td>{row?.cc}</td>
                  <td>{row?.bcc}</td> */}
                  <td>{row?.createdAt}</td>
                  <td>
                    <ShowStatus status={row['status'] ? 'Sent' : 'Unsent'} />
                  </td>
                  <td
                    className="tmPointer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <span onClick={() => handleClick(row, 'view')}>
                      <IoEyeOutline size={18} />
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div>
          <TmPagination
            total={rows.length}
            onShowSizeChange={onShowSizeChange}
            handlePagination={handlePagination}
          />
        </div>
      </div>
    </>
  );
};

export default NotificationTable;
