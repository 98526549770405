import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, DatePicker, TimePicker } from 'antd';
import { Row, Col } from 'reactstrap';
import dayjs from 'dayjs';
import { DisplayDateFormat } from '../../constant/constant';
import Mandatory from '../ReusableComponents/Mandatory';
import { Permissions } from '../../Api/Permisisons';
import { toastError, toastInfo, toastSuccess } from '../Toast/ToastComponent';
import { useAuth } from '../../Context/AuthContext';
import { Leave } from '../../Api/LeaveApi';
import { formatToTodayDate } from '../../ReusableFn/reusableFn';

const ONE_BY_SIXTY = 0.0166666666666667;

const ApplyPermission = ({ modalData, getLeaveManageList, toggleModal }) => {
  const [form] = Form.useForm();

  const { authState } = useAuth();

  const [holidayList, setHolidayList] = useState([]);

  const startDate = Form.useWatch('startDate', form);

  const endDate = Form.useWatch('endDate', form);

  const { Option } = Select;

  const onSubmit = async (data) => {
    data.month = dayjs(data.date).format('MMMM');
    data.year = dayjs(data.date).year();
    data.date = dayjs(data.date).format('YYYY-MM-DD');
    data.time = [
      formatToTodayDate(data.date, data.time[0]),
      formatToTodayDate(data.date, data.time[1]),
    ];
    data.hourCount = data.hourCount.toString();
    try {
      if (authState.id == 1 || authState.id == 2) {
        toastInfo('Application Admin cannot create leave requests.');
        return;
      }

      let newData = { ...data, user_id: authState.id };

      if (modalData.hasOwnProperty('id')) {
        newData.id = modalData?.id;
      }

      const response = modalData.hasOwnProperty('id')
        ? await Permissions.edit(newData)
        : await Permissions.create(newData);

      if (response?.data?.success) {
        form.resetFields();
        toastSuccess(response?.data?.message);
        getLeaveManageList();
        toggleModal();
      } else {
        toastInfo(response?.data?.message);
      }
    } catch (error) {
      toastError(error.response?.data?.message);
    }
  };

  const getHolidayList = async () => {
    try {
      const response = await Leave.getHolidayList();
      if (response?.data?.success) {
        setHolidayList(response?.data?.result);
      } else {
        toastInfo(response?.data?.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  useEffect(() => {
    if (modalData.hasOwnProperty('id')) {
      form.setFieldsValue({
        reason: modalData.reason,
        hourCount: modalData.hourCount,
        date: dayjs(modalData.dateString, 'DD-MM-YYYY'),
        time: [dayjs(modalData.time[0]), dayjs(modalData.time[1])],
      });
    }
    getHolidayList();
  }, [modalData]);

  const customizeRequiredMark = (label, { required }) => (
    <>
      {label}
      {required && <Mandatory />}
    </>
  );

  const convertTimeToOne = (timespentArr) => {
    let milliSec =
      dayjs(timespentArr[1]).valueOf() - dayjs(timespentArr[0]).valueOf();
    let calMints = milliSec / (1000 * 60);
    let mintsToRound =
      calMints * ONE_BY_SIXTY > 0 ? calMints * ONE_BY_SIXTY : 0;
    return mintsToRound || 0;
  };

  function formatNumber(mintsToRound) {
    const roundedNumber = Number(mintsToRound).toFixed(2);
    return roundedNumber.endsWith('.00')
      ? Math.floor(Number(roundedNumber))
      : roundedNumber;
  }

  const handleTimeSpent = async (timeSpent) => {
    if (timeSpent === null || timeSpent.length <= 0) return;

    const mintsToRound = convertTimeToOne(timeSpent);

    const formattedTimeSpent = formatNumber(mintsToRound);

    form.setFieldsValue({ hourCount: formattedTimeSpent });
  };

  return (
    <>
      <div>
        <Form
          layout="vertical"
          form={form}
          onFinish={onSubmit}
          requiredMark={customizeRequiredMark}
        >
          <Row>
            <Col>
              <div className="row justify-content-start">
                <Form.Item
                  className="w-100"
                  name="date"
                  label="Date"
                  rules={[
                    { required: true, message: 'This field is required' },
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    // format={DateFormat}
                    format={DisplayDateFormat}
                    minDate={dayjs()}
                    placeholder="DD-MM-YYYY"
                  />
                </Form.Item>
                <Form.Item
                  name="reason"
                  label="Reason"
                  rules={[
                    { required: true, message: 'This field is required' },
                  ]}
                >
                  <Input.TextArea rows={4} placeholder="Write here" />
                </Form.Item>
                <div className="col-10">
                  <Form.Item
                    label="Permission Time"
                    name="time"
                    style={{ marginBottom: 0 }}
                    rules={[
                      { required: true, message: 'This field is required' },
                    ]}
                  >
                    <TimePicker.RangePicker
                      format="h:mm A"
                      allowClear={false}
                      onChange={(e) => handleTimeSpent(e)}
                      style={{
                        marginBottom: 0,
                        width: '100%',
                      }}
                      autoFocus
                      needConfirm={false}
                    />
                  </Form.Item>
                </div>
                <div className="col-2">
                  <Form.Item name="hourCount" label={<>&nbsp;</>}>
                    <Input readOnly type="number" />
                  </Form.Item>
                </div>
              </div>

              <div className="text-center antdBtn">
                <Button
                  onClick={() => toggleModal()}
                  type="default"
                  htmlType="button"
                >
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default ApplyPermission;
