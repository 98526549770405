import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import style from '../../Styles/Project.module.css';

import noImage from '../../Assets/images.jpg';
import { FaArrowLeft } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ProjectApi } from '../../Api/ProjectApi';
import { toastError } from '../Toast/ToastComponent';
import Loader from '../ReusableComponents/Loader';
import { Button } from 'antd';
import { FiEdit } from 'react-icons/fi';
import { NORECORD } from '../../constant/constant';

const ViewProject = () => {
  const [loading, setLoading] = useState(false);

  const [viewData, setViewData] = useState({});

  const { edit_id, editPermission, view_type } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    getViewProject();
  }, []);

  const getViewProject = async () => {
    let reqData = atob(edit_id);

    try {
      let response = await ProjectApi.getProjectByID(reqData);
      if (response?.data?.success) {
        setViewData(response?.data?.result[0]);
        setLoading(true);
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };
  const handleEdit = () => {
    // navigate(`/add_Project/${atob(edit_id)}`);
    navigate(`/add_Project/${view_type}/${edit_id}`);
  };

  return (
    <>
      {' '}
      {loading && (
        <Row className=" m-0">
          <div className="row  p-0 ">
            <div className="col px-0 m-0 ">
              <div className="d-flex mb-0 ps-2 ">
                <div className="">
                  <div
                    className={`${style.arrow} border px-2 pb-1`}
                    onClick={() => navigate(`/projects/${view_type}`)}
                  >
                    <FaArrowLeft />
                  </div>
                </div>
                <div className="ps-1 ">
                  <div className={`h5 mb-0  `}>
                    {viewData?.project_name ?? '-'}
                  </div>
                  <div className={` pt-1  ${style.color1}`}>
                    <p>{viewData?.customer_name ?? '-'}</p>{' '}
                  </div>
                </div>
              </div>
            </div>

            <div className="col m-0 p-0   ">
              <div className={`text-end m-0 p-0 `}>
                {atob(editPermission) == 'true' && (
                  <Button
                    type="primary"
                    htmlType="button"
                    title="EDIT"
                    className="px-2"
                    onClick={handleEdit}
                  >
                    <div>
                      <FiEdit className="mb-1" size={18} />
                    </div>
                  </Button>
                )}
              </div>
            </div>
          </div>

          <Col md={4} className="px-0">
            <div className={` rounded border ${style.viewInformation}`}>
              <div className="p-2">
                <img
                  className="w-25 border rounded  "
                  src={
                    viewData?.fileInfo?.length
                      ? viewData?.fileInfo[0]?.url
                      : noImage
                  }
                  alt="image"
                />
              </div>
              <div className="ps-2  pt-2">
                <div className={` ${style.color1}`}>Project Name</div>
                <div className=" pt-1">
                  <b>{viewData?.project_name ?? '-'}</b>
                </div>
              </div>
              <div className="ps-2  pt-3">
                <div className={` ${style.color1}`}>Customer Name</div>
                <div className="pt-1">
                  <b>{viewData?.customer_name ?? '-'}</b>
                </div>
              </div>
              <div className="ps-2  pt-3">
                <div className={` ${style.color1}`}>Description</div>
                <div className="pt-1">
                  <b> {viewData?.description ?? '-'}</b>
                </div>
              </div>
              <div className="ps-2 pt-3">
                <div className={` ${style.color1}`}>Billing Method</div>
                <div className="pt-1">
                  <b> {viewData?.billing_method ?? '-'}</b>
                </div>
              </div>
              <div className="ps-2 pt-3">
                <div className={` ${style.color1}`}>Department Name</div>
                <div className="pt-1">
                  <b>{viewData?.department_view ?? '-'}</b>
                </div>
              </div>
              <div className="ps-2 pt-3">
                <div className={` ${style.color1}`}>Project Manager</div>
                <div className="pt-1">
                  <b>{viewData?.project_manager_name ?? '-'}</b>
                </div>
              </div>
              <div className="ps-2 pt-3">
                <div className={` ${style.color1}`}>Start Date</div>
                <div className="pt-1">
                  {' '}
                  <b>{viewData?.start_date ?? '-'}</b>
                </div>
              </div>
              <div className="ps-2 pt-3 mb-2">
                <div className={` ${style.color1}`}>End Date</div>
                <div className="pt-1">
                  <b>{viewData?.end_date ?? '-'}</b>
                </div>
              </div>
            </div>
          </Col>
          <Col md={8} className="px-0 px-md-2 mt-3 mt-md-0">
            <Row className="mx-0 ">
              <Col sm={6} md={3} className="px-0 pe-md-2">
                <div
                  className={`border rounded  ${style.TotalHours} px-2 pb-3`}
                >
                  <div>
                    <div className={` fw-bold h6 pt-3 ${style.color2} `}>
                      {viewData?.totalModule ?? '0'}
                    </div>

                    <div className={` pt-1 ${style.color8} `}>Modules</div>
                  </div>
                </div>
              </Col>
              <Col sm={6} md={3} className="px-0 pe-md-2">
                <div
                  className={`border rounded  ${style.TotalHours} px-2 pb-3`}
                >
                  <div>
                    <div className={` fw-bold h6 pt-3 ${style.color5} `}>
                      {viewData?.totalHours ?? '0'}&nbsp;hrs
                    </div>

                    <div className={` pt-1 ${style.color8} `}>Hours Worked</div>
                  </div>
                </div>
              </Col>
              <Col sm={6} md={3} className="px-0 pe-md-2">
                <div
                  className={` border  rounded ${style.TotalHours} px-2 pb-3`}
                >
                  <div>
                    <div className={`fw-bold h6 pt-3 ${style.color4} `}>
                      {viewData?.totalBillableHours ?? '0'}&nbsp;hrs
                    </div>

                    <div className={` pt-1 ${style.color8} `}>
                      Billable Hours
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={6} md={3} className="px-0">
                <div
                  className={` border rounded ${style.TotalHours} px-2 pb-3`}
                >
                  <div>
                    <div className={` fw-bold h6 pt-3  ${style.color3}`}>
                      <div>
                        {viewData?.totalNonBillableHours ?? '0'}&nbsp;hrs
                      </div>
                    </div>

                    <div className={` pt-1 ${style.color8} `}>
                      Non Billable Hours
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mx-0">
              <Col className="px-0 ">
                <div className={`border rounded  `}>
                  <div
                    className={`ps-2 ps-md-3 pt-2 h6 mt-2 mb-0 ${style.color6}`}
                  >
                    Team Members
                  </div>
                  <div className={`mt-3 m-2 m-md-3 ${style.viewTable}`}>
                    <table className="table">
                      <thead>
                        <tr className={`${style.tablenowrap}`}>
                          <th>S. No</th>
                          <th>User</th>
                          <th>Email</th>
                          <th>Designation</th>
                        </tr>
                      </thead>

                      <tbody>
                        {viewData?.project_members?.map((item, index) => (
                          <tr key={item.id}>
                            <td>
                              <div className={`${style.color7}`}>
                                {index + 1}
                              </div>
                            </td>
                            <td className={`${style.color7}`}>
                              <div className={`${style.color7}`}>
                                {item?.value || '-'}
                              </div>
                            </td>
                            <td className={`${style.color7}`}>
                              <div className={`${style.color7}`}>
                                {item?.email || '-'}
                              </div>
                            </td>
                            <td className={`${style.color7}`}>
                              <div className={`${style.color7}`}>
                                {item?.designation_name || '-'}
                              </div>
                            </td>
                          </tr>
                        ))}
                        {viewData?.project_members.length <= 0 && (
                          <tr>
                            <td colSpan={100} className="text-center">
                              {NORECORD}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {!loading && <Loader />}
    </>
  );
};

export default ViewProject;
