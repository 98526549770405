import { Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import ActionButton from '../ReusableComponents/ActionButton';
import ShowStatus from '../ReusableComponents/ShowStatus';
import { NORECORD, PaginationRange } from '../../constant/constant';
import TmPagination from '../ReusableComponents/Pagination';
import { useNavigate } from 'react-router-dom';

const LeaveManagementTable = (props) => {
  const { rows, columns, tableCustom, handleClick, buttonPermission } = props;
  let columnKey = [];
  const [pagination, setPagination] = useState([]);
  let customKey = [...tableCustom?.columnCtrl];
  let hideBtn = tableCustom?.hideBtn;
  if (rows.length > 0) {
    columnKey = columns.map((item) => item.field);
  }

  useEffect(() => {
    PaginationRange.setPageSize(10);
    handlePagination(1, rows);
  }, [rows]);

  const navigate = useNavigate();

  const handlePagination = async (data, current = []) => {
    let endRange = data * Number(PaginationRange.endRange) - 1;
    let startRange =
      data * Number(PaginationRange.startRange) -
      Number(PaginationRange.initalStart);
    const check = current.length ? current : rows;
    setPagination(
      check.filter((_, index) => index <= endRange && index >= startRange),
    );
  };

  const onShowSizeChange = (current, pageSize) => {
    PaginationRange.setPageSize(pageSize);
    handlePagination(current, rows);
  };
  return (
    <div>
      <Table responsive className="border">
        <thead>
          <tr>
            {customKey.includes('checkbox') && (
              <th className="text-center">
                <Checkbox />
              </th>
            )}
            {columns.map((column, index) => (
              <th className={`${column.className}`} key={index + 1}>
                {column.headerName}
              </th>
            ))}
            {customKey.includes('action') && <th>ACTION</th>}
          </tr>
        </thead>
        <tbody>
          {pagination.length <= 0 && (
            <tr>
              <td colSpan={100} className="text-center">
                {NORECORD}
              </td>
            </tr>
          )}
          {pagination.map((row, idx) => (
            <tr className="hoverTr" key={idx + 1}>
              {customKey.includes('checkbox') && (
                <td className="text-center">
                  <Checkbox />
                </td>
              )}
              {columnKey.map((item, index) => (
                <td
                  key={index + 1}
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleClick(row.id, 'View')}
                >
                  {item !== 'status' ? (
                    <span title={row[item]}> {row[item] || '--'}</span>
                  ) : (
                    <ShowStatus status={row[item]} />
                  )}
                </td>
              ))}
              {customKey.includes('action') && (
                <td>
                  {
                    <ActionButton
                      item={row.id}
                      handleClick={handleClick}
                      hideBtn={hideBtn}
                    />
                  }
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="mt-lg-0 mt-3">
        <TmPagination
          onShowSizeChange={onShowSizeChange}
          total={rows.length}
          handlePagination={handlePagination}
        />
      </div>
    </div>
  );
};

export default LeaveManagementTable;
