import React, { useEffect } from 'react';
import '../../Styles/Login.css';
import LoginApi from '../../Api/LoginApi';
import { toastError, toastSuccess } from '../Toast/ToastComponent';
import { useAuth } from '../../Context/AuthContext';
import { Button, Form, Input } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { removeAuthInfo } from '../../Context/AuthSessionStorage';

const customizeRequiredMark = (label, { required }) => <>{label}</>;

const ResetPassword = () => {
  const { login, authState } = useAuth();
  const [form] = Form.useForm();
  const { emailId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (authState?.id) {
      removeAuthInfo();
    }
    form.setFieldValue('email', atob(emailId));
  }, []);

  const handleLogin = async (data) => {
    let reqData = { ...data };
    delete reqData.confirm_password;
    try {
      let response = await LoginApi.changePassword(reqData);
      if (response?.data?.success) {
        toastSuccess(response.data.message);
        navigate('/');
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  return (
    <div className="d-flex flex-column m-0 justify-content-center align-item-center">
      <div className="text-center ">
        <h2 className="fw-bold">RESET PASSWORD</h2>
      </div>
      <div className="mt-4">
        <div className="row justify-content-center">
          <div className="col col-md-8">
            <Form
              name="basic"
              form={form}
              requiredMark={customizeRequiredMark}
              layout="vertical"
              onFinish={handleLogin}
              autoComplete="off"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'Please enter a valid email',
                  },
                  {
                    required: true,
                    message: 'Please enter your email',
                  },
                ]}
              >
                <Input
                  type="email"
                  readOnly
                  size="large"
                  placeholder="Enter Email"
                />
              </Form.Item>

              <Form.Item
                label="Password"
                name="newpassword"
                rules={[
                  { required: true, message: 'Please enter your password' },
                ]}
              >
                <Input.Password size="large" placeholder="Enter Password" />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 35 }}
                label="Confirm Password"
                name="confirm_password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your confirm password',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value, confrm) {
                      if (!value || getFieldValue('newpassword') === value) {
                        // return Promise.resolve();
                        confrm();
                      }
                      confrm('The new password that you entered do not match!');
                      // return Promise.reject(new Error('The new password that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="Enter Confirm Password"
                />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 24 }} style={{ marginBottom: 7 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{ width: '100%' }}
                >
                  Reset
                </Button>
              </Form.Item>
              <div className="text-end">
                <Link to={'/login'} className="linkColor">
                  Login
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
