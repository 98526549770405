import { DateFormat, MONTHS_INDEX } from '../constant/constant';
import dayjs from 'dayjs';

export const dayjsToDateFormat = (date) => {
  return dayjs(date).format(DateFormat);
};
export const dateFormatToDayjs = (date) => {
  return dayjs(dayjs(date).format(DateFormat), DateFormat);
};
export const dateFormatToHHmm = (date) => {
  return dayjs(dayjs(date), 'HH:mm');
};

export const statusColorSet = (data) => {
  const checkVal = data.toLowerCase();
  switch (checkVal) {
    case 'completed':
      return 'status-completed';
    case 'inprogress':
      return 'status-inprogress';
    case 'over due':
      return 'status-overdue';
    default:
      return 'status-uninitiated';
  }
};

export const generateYears = () => {
  let startYear = 2020,
    currentYear = dayjs().year();
  currentYear += 10;
  let objArr = [];
  for (let i = startYear; i <= currentYear; i++) {
    objArr.push({ label: i, value: i });
  }
  return objArr;
};

export const generateDays = () => {
  let array = [{ label: 1, value: 1 }];
  for (const element of array) {
    let obj = { ...element };
    if (element.value < 31) {
      obj.label += 1;
      obj.value += 1;
      array.push(obj);
    }
  }
  return array;
};

export const generateCurrentMonth = () => {
  return new Date().toLocaleString('en-US', {
    month: 'long',
  });
};
export const getMonthFromDate = (date) => {
  return new Date(dayjs(date)).toLocaleString('en-US', {
    month: 'long',
  });
};
export const getYearFromDate = (date) => {
  return new Date(dayjs(date)).getFullYear();
};
export const generateCurrentYear = () => {
  return dayjs().year();
};
export const collectKeyValueInArr = (arr, key) => {
  let newArr = arr || [];
  if (!!key && newArr?.length !== 0) {
    return newArr.map((item) => item[key]);
  }
  return [];
};

export const GET_CURRENT_MONTH_IDX = MONTHS_INDEX.find(
  (item) => item.label === generateCurrentMonth(),
)['value'];

export const formatToTodayDate = (currDate, OgDate) => {
  const currentDate = dayjs(currDate);
  const originalDate = dayjs(OgDate);
  const updatedDate = currentDate
    .hour(originalDate.hour())
    .minute(originalDate.minute())
    .second(originalDate.second())
    .millisecond(0);

  return updatedDate || dayjs();
};
