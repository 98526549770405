import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Switch, Select } from 'antd';
import { useAuth } from '../../Context/AuthContext';
import {
  toastSuccess,
  toastWarn,
  toastError,
  toastInfo,
} from '../Toast/ToastComponent';
import CustomEditorMCE from './CustomEditorMCE';
import Mandatory from '../ReusableComponents/Mandatory';
import EmailApi from '../../Api/EmailApi';
import { ConsoleSqlOutlined, PlusOutlined } from '@ant-design/icons';
import { EMAIL_NOTIFICATIONS } from '../../constant/constant';
import Department from '../../Api/Department';
import UserApi from '../../Api/UserApi';
import {
  FORGET_PASSWORD,
  USER_CREATION,
  TIMESHEET_LOCKED,
  TIMESHEET_REMAINDER,
  LEAVE_SUBMISSION,
  LEAVE_APPROVAL,
  LEAVE_REJECTION,
  MONTH_APPROVAL,
  PERMISSION_SUBMISSION,
  PERMISSION_APPROVAL,
  PERMISSION_REJECTION,
} from '../../helpers/HtmlContent';
import { API_BASE_URL } from '../../config/appConfig';

const customizeRequiredMark = (label, { required }) => (
  <>
    {label}
    {required && <Mandatory />}
  </>
);

const { Option } = Select;


const AddEmail = ({
  getEmailList,
  modaldata,
  setModal,
  modal,
  modalHeader,
}) => {
  const [form] = Form.useForm();
  const { authState } = useAuth();

  const [departments, setDepartment] = useState([]);
  const [userList, setUserList] = useState([]);
  const [emailList, setEmailList] = useState([]);

  const [fieldManipulate, setFieldManipulate] = useState({
    isRequired: false,
    isDisabled: false,
  });

  let bcc = Form.useWatch('bccID', form);

  let cc = Form.useWatch('ccID', form);

  const [formValues, SetFormValues] = useState({
    title: '',
    content: '',
    type: null,
    status: true,
    bccID: [],
    ccID: [],
    departmentsID: [],
    subject: '',
  });

  const handleFieldManipulate = async (key, value) => {
    setFieldManipulate((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const getDepartment = async (IDs) => {
    try {
      let body;
      if (IDs?.length) {
        body = {
          IDs,
        };
      }
      const response = await Department.dropDown(body);
      if (response?.data?.success) {
        setDepartment(response?.data?.result);
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  const getUsersEmail = async () => {
    try {
      let response = await UserApi.getEmail();
      if (response.data.success) {
        setUserList(response?.data?.result);
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  const getList = async (type) => {
    try {
      const response = await EmailApi.getEmailList();
      if (response?.data?.success) {
        setEmailList(response?.data?.result);
        if (type) {
          return response?.data?.result;
        }
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  const handleSubmit = async (reqData) => {
    try {
      if (!formValues.content) {
        toastInfo('Content is Required');
        return;
      }

      let response = !!modaldata.title
        ? await EmailApi.update({
            ...reqData,
            id: modaldata.id,
            content: formValues.content,
          })
        : await EmailApi.create({ ...reqData, content: formValues.content });
      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        form.resetFields();
        getEmailList();
        SetFormValues({
          title: '',
          content: '',
          type: '',
          status: true,
          bccID: [],
          ccID: [],
          departmentsID: [],
          subject: '',
        });
        setModal(!modal);
      } else {
        toastError(response?.data?.message);
        return;
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  const handleChangeData = async (data, type) => {
    let fieldData;

    let obj = {
      'FORGET PASSWORD': FORGET_PASSWORD,
      'LOCK TIMESHEET': TIMESHEET_LOCKED,
      'LOCK REMAINDER': TIMESHEET_REMAINDER,
      'USER CREATION': USER_CREATION,
      'LEAVE SUBMISSION': LEAVE_SUBMISSION,
      'PERMISSION SUBMISSION': PERMISSION_SUBMISSION,
      'PERMISSION APPROVAL': PERMISSION_APPROVAL,
     'PERMISSION REJECTION': PERMISSION_REJECTION,
      'LEAVE APPROVAL': LEAVE_APPROVAL,
      'LEAVE REJECTION': LEAVE_REJECTION,
      'MONTH APPROVAL': MONTH_APPROVAL,
    };
    let imageObj = {
      'FORGET PASSWORD': `${API_BASE_URL}/assets/Forget pasword.png`,
      'LOCK TIMESHEET': `${API_BASE_URL}/assets/Locktimesheet.png`,
      'LOCK REMAINDER': `${API_BASE_URL}/assets/Locktimesheet.png`,
      'USER CREATION': `${API_BASE_URL}/assets/UserCreation.png`,
      'PERMISSION SUBMISSION': `${API_BASE_URL}/assets/Leave Submitted.png`,
      'PERMISSION APPROVAL': `${API_BASE_URL}/assets/Leave Approved.png`,
      'PERMISSION REJECTION': `${API_BASE_URL}/assets/Leave Rejected.png`,
      'LEAVE SUBMISSION': `${API_BASE_URL}/assets/Leave Submitted.png`,
      'LEAVE APPROVAL': `${API_BASE_URL}/assets/Leave Approved.png`,
      'LEAVE REJECTION': `${API_BASE_URL}/assets/Leave Rejected.png`,
      'MONTH APPROVAL': `${API_BASE_URL}/assets/Timesheet approved.png`,
    };
    if (obj.hasOwnProperty(data)) {
      let departmentRequired = emailList.some(
        (element) =>
          element.type == data &&
          (!element.departmentsID || !element.departmentsID.length),
      );
      handleFieldManipulate('isRequired', departmentRequired);
    }

    fieldData = type ? obj[data] : data;
    
    let imageData = imageObj[data];

    let passwordFields = [
      {
        fieldName: '#Image#',
        value: imageData,
      },
      {
        fieldName: '#companyLogo#',
        value: `${API_BASE_URL}/assets/companyLogo.jpg`,
      },
    ];

    for (const element of passwordFields) {
      const regex = new RegExp(element.fieldName, 'g');
      fieldData = fieldData.replace(regex, element.value);
    }

    SetFormValues((pre) => ({
      ...pre,
      content: fieldData,
    }));

    if (type) {
      const array = [];

      const addIdsToArray = (ids) => {
        ids.forEach((id) => {
          if (!array.includes(id)) array.push(id);
        });
      };

      emailList.forEach((element) => {
        if (element?.type === data && element?.departmentsID?.length) {
          if (modaldata?.hasOwnProperty('departmentsID')) {
            if (modaldata?.type === data) {
              const newIds = element.departmentsID.filter(
                (id) => !modaldata?.departmentsID?.includes(id),
              );
              addIdsToArray(newIds);
            }
          } else {
            addIdsToArray(element.departmentsID);
          }
        }
      });

      await getDepartment(array);
      form.setFieldValue('departmentsID', []);
    }
  };

  const getExistDepartment = async (type, Ids) => {
    let emails = await getList(type);
    if (type) {
      let array = [];
      for (const element of emails) {
        if (element?.type == type) {
          if (element?.departmentsID?.length) {
            for (const id of element?.departmentsID) {
              if (!Ids?.includes(id)) {
                array.push(id);
              }
            }
          }
        }
      }

      await getDepartment(array);
    }
  };

  useEffect(() => {
    getUsersEmail();
    if (!!modaldata.title) {
      SetFormValues((pre) => ({
        ...pre,
        title: modaldata.title,
        content: modaldata.content,
        type: modaldata.type,
        status: modaldata.status,
        bccID: modaldata.bccID,
        ccID: modaldata.ccID,
        departmentsID: modaldata.departmentsID,
        subject: modaldata.subject,
      }));

      getExistDepartment(modaldata.type, modaldata.departmentsID);
      form.setFieldsValue(modaldata);
      if (!modaldata?.departmentsID?.length || !modaldata?.departmentsID) {
        handleFieldManipulate('isDisabled', true);
      } else {
        handleFieldManipulate('isRequired', true);
      }
    } else {
      getList();
    }
  }, [modaldata]);

  const validateInput = (_, value) => {
    if (value !== value.trim() || value.includes('  ')) {
      return Promise.reject(new Error(' Extra spaces are not allowed'));
    }

    return Promise.resolve();
  };

  return (
    <>
      <Form
        form={form}
        requiredMark={customizeRequiredMark}
        onFinish={handleSubmit}
        initialValues={formValues}
        layout="vertical"
      >
        <div className="d-flex flex-column justify-content-between h-100">
          <div>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,

                  message: 'This field is required',
                },
                {
                  validator: validateInput,
                },
              ]}
            >
              <Input
                disabled={modalHeader?.props?.children == 'View Email'}
                placeholder="Enter title"
              />
            </Form.Item>
            <Form.Item
              name="type"
              label="Event Type"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Select
                disabled={
                  modalHeader?.props?.children == 'View Email' ||
                  fieldManipulate?.isDisabled
                }
                allowClear={true}
                value={formValues.type}
                type="select"
                onChange={(value) => handleChangeData(value, 'setContent')}
                placeholder="Select Type"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {EMAIL_NOTIFICATIONS.map((item, idx) => (
                  <Select.Option key={idx} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="departmentsID"
              label="Department"
              rules={[
                {
                  required: [1, 2, 3, 4].includes(modaldata.id)
                    ? false
                    : fieldManipulate?.isRequired,
                  message: 'This field is required',
                },
              ]}
            >
              <Select
                disabled={
                  modalHeader?.props?.children == 'View Email' ||
                  fieldManipulate?.isDisabled
                }
                allowClear={true}
                value={formValues.type}
                type="select"
                placeholder="Select Department"
                showSearch
                mode="multiple"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {departments.map((item, idx) => (
                  <Select.Option
                    key={idx}
                    value={item.value}
                    disabled={!item.status}
                  >
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Subject"
              name="subject"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
                {
                  validator: validateInput,
                },
              ]}
            >
              <Input
                disabled={modalHeader?.props?.children == 'View Email'}
                placeholder="Enter subject"
              />
            </Form.Item>

            <Form.Item name="ccID" label="CC">
              <Select
                disabled={modalHeader?.props?.children == 'View Email'}
                type="select"
                style={{ width: '100%' }}
                placeholder="Select"
                mode="multiple"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {userList?.map(
                  (element, indx) =>
                    !bcc?.includes(element.value) && (
                      <Option key={indx} value={element.value}>
                        {element.label}
                      </Option>
                    ),
                )}
              </Select>
            </Form.Item>

            <Form.Item name="bccID" label="BCC">
              <Select
                disabled={modalHeader?.props?.children == 'View Email'}
                mode="multiple"
                style={{ width: '100%' }}
                type="select"
                placeholder="Select"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {userList?.map(
                  (element, indx) =>
                    !cc?.includes(element.value) && (
                      <Option key={indx} value={element.value}>
                        {element.label}
                      </Option>
                    ),
                )}
              </Select>
            </Form.Item>

            <Form.Item label="Content" name="content">
              <CustomEditorMCE
                disabled={modalHeader?.props?.children == 'View Email'}
                editorState={formValues.content}
                setEditorState={(value) => {
                  handleChangeData(value);
                }}
              />
            </Form.Item>

            <Form.Item label="Status" name="status">
              <Switch disabled={modalHeader?.props?.children == 'View Email'} />
            </Form.Item>
          </div>
          {modalHeader?.props?.children !== 'View Email' && (
            <div className="text-center antdBtn">
              <Button
                type="default"
                size="medium"
                htmlType="button"
                onClick={() => setModal(!modal)}
              >
                Cancel
              </Button>
              <Button type="primary" size="medium" htmlType="submit">
                Save
              </Button>
            </div>
          )}
        </div>
      </Form>
    </>
  );
};

export default AddEmail;

{
  /* 
              <div className="pillBtns d-flex gap-1 pt-2">
                <button class="btn btn-light btn-sm border rounded-pill d-flex align-items-center p-0">
                  <span className="px-2">
                    <PlusOutlined />
                    &nbsp;Name
                  </span>
                </button>
                <button class="btn btn-light btn-sm border rounded-pill d-flex align-items-center p-0 ">
                  <span className="px-2">
                    <PlusOutlined />
                    &nbsp;Date
                  </span>
                </button>
                <button class="btn btn-light btn-sm border rounded-pill d-flex align-items-center p-0 ">
                  <span className="px-2">
                    <PlusOutlined />
                    &nbsp;Time
                  </span>
                </button>
                <button class="btn btn-light btn-sm border rounded-pill d-flex align-items-center p-o">
                  <span className="px-2">
                    <PlusOutlined />
                    &nbsp;Project Name
                  </span>
                </button>
              </div> */
}
